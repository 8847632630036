@import 'assets/styles/mixins';
@import 'assets/styles/media.scss';

.content {
	&__header {
		@include d-flex(row, null, center);
		height: 44px;
		margin-top: 0px;

		&__icon {
			margin: 12px;
			width: 20px;
			height: 20px;
			cursor: pointer;
			position: absolute;
		}

		&__title {
			@include typography(var(--color-primary-800), 20px, 700, 160%, 0.15);
			text-align: center;
			width: 100%;
		}
	}

	&__list {
		width: 100%;
		margin: 0 auto;

		&_empty {
			@include d-flex(column, null, center);
			font-weight: bold;
			font-size: 35px;
			padding-top: 10vh;

			&__text {
				@include typography(var(--color-neutral-300), 23px, 700, 133.4%);
				margin-top: 32px;
				text-align: center;
				margin-bottom: 68px;
			}

			&__icon {
				border-radius: 50%;
				min-width: 50px;
				min-height: 50px;
			}
		}
	}
}

@include mobile {
	.header {
		@include d-flex(row, null, center, 20px);
		margin-top: 20px;

		&__icon {
			margin: 0 0 0 12px;
			position: relative;
		}
	}
}
