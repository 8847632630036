.input-wrapper {
	width: 100%;
	z-index: 0;
	height: 57px;

	& .MuiTextField-root {
		z-index: 0 !important;
	}

	input {
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}
	}
}
