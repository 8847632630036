@import 'assets/styles/mixins';
@import 'assets/styles/media';

.content {
	@include d-flex(column, flex-start, center, var(--spacing-2));
	width: 100%;
	padding: var(--spacing-4);

	&_disabled {
		& > * {
			filter: grayscale(1);
		}
	}

	span {
		width: 100%;
		word-break: keep-all;
	}

	&__icon {
		@include d-flex(column, center, center);
		aspect-ratio: 1 / 1;
		height: 52px;
		padding: var(--spacing-3);
		position: relative;
		background-color: var(--color-primary-500);
		border-radius: 50%;

		img {
			@include category-icon-filter;
			width: 100%;
			object-fit: contain;
		}
	}

	@include mobile {
		padding: var(--spacing-3) var(--spacing-2);
		gap: 4px;

		span {
			font-size: 14px;
		}

		&__icon {
			height: var(--spacing-9);
			padding: var(--spacing-2);
		}
	}
}
