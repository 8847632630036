@import 'assets/styles/mixins';

.content {
	@include d-flex(row, space-between, center, var(--spacing-3));
	width: 100%;
	height: 60px;
	border-bottom: 1px solid var(--color-neutral-50);
	cursor: pointer;

	&__label {
		@include d-flex(row, flex-start, center, var(--spacing-2));
	}

	&__icon {
		@include d-flex(row, center, center);
		flex-shrink: 0;
	}

	& > p:first-of-type {
		@include d-flex(row, flex-start, center, var(--spacing-3));
	}

	&:last-child {
		border-bottom: none;
	}

	&_view {
		cursor: auto;
	}

	&__value {
		@include text-overflow;
	}
}
