@import 'assets/styles/mixins';
@import 'assets/styles/media.scss';

.wrapper {
	@include d-flex(column, space-between);
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	position: absolute;
	z-index: 2;
	padding: var(--spacing-6);

	&.multiple {
		justify-content: center;
		height: 100%;
	}

	& .button {
		max-width: 100%;
		width: 100%;
	}

	.actions {
		position: absolute;
		z-index: 2;
		right: var(--spacing-3);
		top: var(--spacing-3);

		button {
			width: 32px;
			border-color: var(--color-primary-500);
			border-radius: var(--spacing-2);
			background: var(--color-primary-500);
		}
	}

	.marker {
		@include absolute-center;

		svg {
			cursor: pointer;
		}
	}

	@include mobile {
		padding: var(--spacing-3);
	}
}
