@import 'assets/styles/mixins';

.content {
    @include d-flex(column, flex-start, center, var(--spacing-9));
    height: 100%;
    width: 100%;
    text-align: center;

    .logo {
        max-width: 45%;
    }

    .details {
        @include d-flex(column);
        gap: var(--spacing-5);
        width: 100%;
        padding: 0 var(--spacing-3);
    }
}
