@import 'assets/styles/mixins';
@import 'assets/styles/media.scss';

.content {
	@include d-flex(column, center, center);
	width: 100%;
	height: 100%;
	// max-width: 685px;
	// max-height: 685px;
	margin: 0px auto;
	padding: var(--spacing-5) 0;

	svg {
		margin-bottom: var(--spacing-9);
	}

	&__info {
		@include d-flex(column, flex-start, center, var(--spacing-3));
		margin: 0 var(--spacing-4);
	}

	@include mobile {
		svg {
			// width: 290px;
			// height: 290px;
			max-height: 299px;
			margin-bottom: var(--spacing-4);
		}
	}
}
