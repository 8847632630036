@import '~assets/styles/mixins';

.content {
	@include d-flex(column);
	gap: var(--spacing-1);
	margin-bottom: var(--spacing-6);

	&:last-of-type {
		margin-bottom: 0;
	}

	&__title {
		@include d-flex(row, space-between, flex-start, var(--spacing-3));
	}

	&__icon {
		width: 16px;
	}
}
