@import 'assets/styles/mixins';
@import 'assets/styles/media';

.marker {
	--marker-color: #67abaf;
	max-width: 160px;
	width: 100%;
	aspect-ratio: 1 / 1;
	z-index: 2;
	background: radial-gradient(61.99% 61.99% at 50% 50%, rgba(103, 171, 175, 0.16) 0%, rgba(103, 171, 175, 0) 100%);
	border-radius: 50%;
	position: relative;

	svg {
		@include absolute-center;
		width: 64px;

		rect,
		path:last-child {
			fill: var(--marker-color);
		}
	}

	&_second {
		--marker-color: #ea832a;
		background: radial-gradient(61.99% 61.99% at 50% 50%, rgba(205, 146, 58, 0.16) 0%, rgba(205, 146, 58, 0) 100%);

		svg {
			rect,
			path:last-child {
				fill: var(--marker-color);
			}
		}

		&.icon {
			svg {
				rect {
					fill: var(--marker-color);
				}
			}
		}
	}

	&_third {
		--marker-color: #ffc89a;
		background: radial-gradient(61.99% 61.99% at 50% 50%, rgba(255, 200, 154, 0.16) 0%, rgba(255, 200, 154, 0) 100%);

		svg {
			rect,
			path:last-child {
				fill: var(--marker-color);
			}
		}

		&.icon {
			svg {
				rect {
					fill: #ffc89a;
				}
			}
		}
	}

	&_big {
		max-width: 340px;
	}

	&.icon {
		max-width: 48px;
		background: none;

		svg {
			path:last-child {
				fill: #fff;
			}
		}
	}

	@include mobile {
		max-width: 132px;

		svg {
			max-width: 48px;
		}

		&_icon {
			max-width: 48px;
			background: none;
		}
	}
}
