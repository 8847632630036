@import '~assets/styles/mixins';

.content {
	width: 100%;
	border-radius: var(--spacing-2);
	border: 1px solid var(--color-primary-500);
	padding: var(--spacing-2) var(--spacing-4);
	height: auto;

	.info {
		@include d-flex(row, space-between, center, var(--spacing-3));
		width: 100%;
		overflow: hidden;

		&__title {
			width: 100%;
			flex-grow: 1;

			&::first-letter {
				text-transform: uppercase;
			}
		}

		&__description {
			white-space: initial;
			overflow: hidden;
			flex-shrink: 1;
			flex-grow: 0;
		}
	}

	&_active {
		background-color: var(--color-primary-500) !important;

		.info > * {
			color: var(--color-neutral-white);
		}
	}

	&_error {
		background-color: var(--color-error-500) !important;
		border-color: var(--color-error-500);
	}
}
