.status {
	color: var(--color-error-500);
	background-color: #ffd4dc;
	border-radius: 100px;
	padding: var(--spacing-1) var(--spacing-3);

	&_success {
		color: var(--color-success-500);
		background-color: #f4fff6;
	}
}
