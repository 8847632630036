@import 'assets/styles/mixins';

section.layout {
	background-color: var(--color-neutral-layout-bg);
	overflow-x: clip;
	height: calc(100dvh - env(safe-area-inset-top));
	padding-top: env(safe-area-inset-top);

	@include mobile {
		grid-template-rows: 100px 1fr;
	}
}
