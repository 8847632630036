.progress {
    margin-bottom: var(--spacing-6);
}

.modal {
    & > p {
        margin-bottom: var(--spacing-5);

        &:not(:first-of-type) {
            text-align: justify;
        }
    }
}