@import 'assets/styles/mixins';

.wrapper {
	display: grid;
	grid-template-rows: auto 1fr auto;
	position: fixed;
	width: 100dvw;
	width: 100vw;
	height: 100dvh;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 99999999;
	overflow: hidden;
	background: var(--color-neutral-white);
	padding-top: calc(var(--spacing-3) + env(safe-area-inset-top));
	padding-bottom: calc(var(--spacing-3) + env(safe-area-inset-bottom));

	& > button:first-child {
		margin: 0 var(--spacing-3);
		margin-bottom: var(--spacing-4);
	}

	.button_submit,
	.viewer {
		max-width: 624px;
		margin: 0 auto;
	}

	.viewer {
		width: 100%;
		height: 100%;
		overflow: hidden;

		&__page {
			@include d-flex(column, flex-start, center, var(--spacing-2));
			overflow-y: auto;
			height: 100%;
			padding: var(--spacing-2) 0;

			canvas {
				margin: 0 auto;
				box-shadow: var(--box-shadow);
			}
		}
	}

	.button {
		&_submit {
			padding: 0 var(--spacing-3);
			margin-top: var(--spacing-2);
		}
	}

	.nav {
		@include d-flex(row, space-between, center, var(--spacing-3));
		width: 100%;
		padding: var(--spacing-2) var(--spacing-4);
		position: sticky;
		bottom: 0;
		background-color: var(--color-neutral-white);

		& > button:last-child {
			transform: rotateY(180deg);
		}
	}
}

@media (min-width: 510px) {
	.viewer {
		&__page {
			canvas {
				width: auto !important;
				height: calc(100% - 50px) !important;
			}
		}
	}
}
