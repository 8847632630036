@import '~assets/styles/mixins';

div.page {
	.content {
		@include d-flex(column);
		width: 100%;
		margin-bottom: auto;

		ul.list {
			@include d-flex(column);
			gap: var(--spacing-3);
			margin-bottom: var(--spacing-3);
		}
	}

	.card {
		width: auto;
	}

	& > ion-button {
		margin-top: var(--spacing-6);
	}
}
