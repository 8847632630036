@import 'assets/styles/mixins';
@import 'assets/styles/media';

.content {
	@include d-flex(row, space-between, center, var(--spacing-3));
	padding-bottom: 8px;

	&__info {
		@include d-flex(row, flex-start, center, var(--spacing-3));

		svg {
			cursor: pointer;
			flex-shrink: 0;
		}
	}

	&__header {
		margin-top: 25px;
	}

	&:last-child {
		padding-bottom: 0;
	}

	@include mobile-small {
		flex-wrap: wrap;
		align-items: flex-start;

		&__info {
			align-items: flex-start;
		}
	}
}

.tariff__list {
	@include d-flex(column);
	gap: var(--spacing-3);
}
