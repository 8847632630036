@import 'assets/styles/mixins';

.content {
	@include d-flex(column);
	height: 100%;

	&__documents {
		margin: var(--spacing-9) 0 var(--spacing-6);
	}

	&__submission {
		margin: auto;
		margin-bottom: var(--spacing-6);
	}
}
