.button {
	&::part(native) {
		width: 52px;
		height: 48px;
		opacity: 0.5;
	}

	&__active {
		&::part(native) {
			border: 1px solid var(--color-neutral-200);
			opacity: 1;
		}
	}
}
