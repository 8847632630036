@import 'assets/styles/mixins';
@import 'assets/styles/media';

@include slider-pagination(var(--color-primary-900), auto, 10px);

.content {
	@include d-flex(column, flex-start);
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding-top: 40px;
	padding-bottom: var(--spacing-3);

	.slider {
		max-height: 746px;
		position: relative;
		width: 100%;
		height: 100%;
		padding: 0 0 80px;
		overflow: visible;

		&__nav {
			@include d-flex(row, space-evenly, center);
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			padding: 0 20%;
			z-index: 11;

			ion-button {
				width: max-content;
			}
		}

		&__slide {
			height: 100%;
			max-width: 685px;
			background-color: var(--color-neutral-white);
			border-radius: var(--main-card-border-radius);
			box-shadow: var(--box-shadow-2);
			overflow: hidden;
			padding: var(--spacing-2) 0;
		}

		@include mobile {
			max-height: 544px;
			padding: 0;
		}
	}

	div.custom_wrapper {
		align-items: center;
	}

	div.custom_no_active_slide {
		filter: blur(4px);

		@include mobile {
			filter: none;
		}
	}

	@include mobile {
		padding-top: 24px;
		padding-bottom: 40px;

		.slider {
			&__nav {
				display: none;
			}
		}
	}
}
