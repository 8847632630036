@import 'assets/styles/mixins';

.content {
	@include d-flex(column);
	gap: var(--spacing-3);
	margin-top: var(--spacing-5);

	.detail {
		@include d-flex(row, space-between, center, var(--spacing-3));
		margin-bottom: var(--spacing-2);
	}

	.list__item {
		user-select: none;
		padding: 0;

		&.disabled {
			opacity: 0.5;
		}

		.tooltip {
			width: 100%;
		}
	}

	.apple__fee {
		gap: var(--spacing-2);
	}

	.button_gPay {
		width: 100%;

		button {
			width: 100%;
			outline: none !important;
		}
	}
}

apple-pay-button {
	--apple-pay-button-width: 100%;
	--apple-pay-button-height: var(--button-height);
	--apple-pay-button-border-radius: var(--button-border-radius);
	--apple-pay-button-padding: 0px 0px;
	--apple-pay-button-box-sizing: border-box;
}
