@import '~assets/styles/mixins';

.content {
	@include d-flex(column);
	gap: var(--spacing-5);
	width: 100%;
	height: 100%;
}

.button_submit {
	position: sticky;
	bottom: 0;
}
