@import 'assets/styles/mixins';
@import 'assets/styles/media';

.content {
	max-width: 448px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin: 0 auto;

	&__actions {
		@include d-flex(row, space-between);

		ion-button {
			width: max-content;
		}

		@media (max-width: 420px) {
			flex-direction: column;
			align-items: center;
		}
	}
}

.input {
	margin-bottom: var(--spacing-3);
}
