@import 'assets/styles/mixins';
@import 'assets/styles/media';

.wrapper {
	width: 100%;
	height: 100%;
	font-weight: bold;
	font-size: 35px;
	overflow: hidden;

	.header {
		display: none;

		@include mobile {
			display: flex;
		}
	}

	.content {
		@include d-flex(column, flex-start);
		width: 100%;
		height: 100%;
		overflow: hidden;
		padding-top: 40px;
		padding-bottom: var(--spacing-3);

		padding-left: 20px;
		padding-right: 20px;
	}

	@include mobile {
		display: grid;
		grid-template-rows: auto 1fr;

		.header {
			@include mobile {
				display: flex;
			}
		}

		.content {
			padding-top: 24px;
			padding-bottom: 40px;
		}
	}
}
