@import 'assets/styles/mixins';

.wrapper {
	@include full-screen-page;
	display: grid;
	grid-template-rows: 1fr auto;
	gap: var(--spacing-6);
	padding: 0 var(--spacing-2);
	padding-top: env(safe-area-inset-top);
	padding-bottom: calc(var(--spacing-4) + env(safe-area-inset-bottom));
	background-color: #fac1f4;
	transition: background-color 0.3s linear;

	&_secondBg,
	&_fourthBg {
		background-color: #c8a8eb;
	}

	&_thirdBg {
		background-color: #fec2c2;
	}

	&_fourthBg {
		background-image: url('~assets/images/fourth_screen_bg.png');
		background-position: bottom;
		background-repeat: no-repeat;
		background-size: 100%;
	}

	.logo {
		margin-left: auto;
		margin-top: var(--spacing-9);
	}

	.slider {
		max-width: 485px;
		width: 100%;
		position: relative;
		padding-bottom: var(--spacing-8);

		&__slide {
			display: grid;
			grid-template-rows: auto auto;
			height: 100%;
			padding: 0 var(--spacing-6);
			overflow: hidden;
		}

		&__image {
			overflow: hidden;
			grid-row: 1 / span 1;

			img {
				height: 100%;
				user-select: none;
				object-fit: contain;
				margin: 0 auto;
			}
		}

		&__horizontal {
			@include d-flex(row, space-between, center, var(--spacing-2));
			height: 8px;
			padding: 0 var(--spacing-6);
		}

		&__bullet {
			display: block;
			position: relative;
			width: 100%;
			height: 100%;
			border-radius: 200px;
			background-color: var(--color-neutral-white);
			opacity: 0.5;

			&_active {
				opacity: 1;
			}
		}
	}

	.actions {
		@include d-flex(column, center, center, var(--spacing-6));
		padding: 0 var(--spacing-6);
		margin-top: auto;

		.button {
			&_skip {
				--padding-start: 0;
				--padding-end: 0;
				--padding-top: 0;
				--padding-bottom: 0;
				width: auto;
			}
		}
	}
}
