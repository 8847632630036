@import 'assets/styles/mixins';

.profile {
	@include d-flex(row, flex-start, center, var(--spacing-4));
	position: relative;
	overflow: hidden;

	&__avatar {
		position: relative;
		flex-shrink: 0;
	}

	&__profileIcon {
		object-fit: cover;
		border-radius: 50%;
		width: 60px;
		height: 60px;

		&__small {
			width: 45px;
			height: 45px;
		}
	}

	&__username {
		@include d-flex(column);
		overflow: hidden;

		& > * {
			@include text-overflow(100%);
		}
	}

	&__edit {
		@include d-flex(column, center, center);
		position: absolute;
		bottom: 0;
		left: 40px;
		width: 28px;
		height: 28px;
		border-radius: 40px;
		border: 1px solid var(--color-neutral-50);
		background: var(--color-neutral-white) !important;
		cursor: pointer;

		svg {
			width: 16px;
			height: 16px;

			path {
				fill: var(--color-neutral-500);
			}
		}
	}
}
