@import 'assets/styles/mixins';

.form {
	@include d-flex(column);
	margin-bottom: calc(var(--spacing-5) + env(safe-area-inset-bottom));
}

.list {
	@include d-flex(column, flex-start, center, var(--spacing-5));
	margin-bottom: var(--spacing-9);
}
