@import './_mixins.scss';
@import './media.scss';
@import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/react/css/normalize.css';
@import '@ionic/react/css/structure.css';
@import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/react/css/padding.css';
@import '@ionic/react/css/float-elements.css';
@import '@ionic/react/css/text-alignment.css';
@import '@ionic/react/css/text-transformation.css';
@import '@ionic/react/css/flex-utils.css';
@import '@ionic/react/css/display.css';

/* Swiper */
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'swiper/css/scrollbar';

:root {
	--box-shadow-1: 0px 4px 12px rgba(51, 75, 78, 0.08);
	--box-shadow-2: 0px 8px 16px rgba(51, 75, 78, 0.12);
	--box-shadow-3: 0px 12px 20px rgba(51, 75, 78, 0.2);
	--box-shadow-4: 0px 8px 20px 0px rgba(51, 75, 78, 0.42);
}

*:not(ion-app) {
	&::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: var(--color-neutral-50);
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: var(--color-neutral-200);
		border-radius: 4px;
	}
}

a {
	text-decoration: none;
}

.auth {
	&-error {
		text-align: center;
	}

	&-button-submit {
		margin-top: 16px;
	}

	&-profile-handlers {
		width: 100%;
		@include d-flex(column, center, center, 28px);
	}
}

.error {
	@include typography(var(--color-error-500), 12px, 400, 140%, 0.4px);
}

.layout {
	display: grid;
	grid-template-rows: 100px 1fr;
	grid-template-columns: 1fr;
	width: 100vw;
	height: 100vh;
	height: 100dvh;
	overflow-y: auto;

	@include mobile {
		grid-template-rows: 1fr auto;
		gap: 0;
		padding: 0;
		padding-top: calc(var(--spacing-3) + env(safe-area-inset-top));
	}
}

.layout-ios {
	&:has(.service-header, .page-scrollable) {
		overflow: hidden;

		.page {
			overflow-y: auto;
		}
	}
}

.page {
	@include d-flex(column, flex-start, center);
	width: 100%;
	max-width: 100vw;
	height: 100%;
	margin: 0 auto;
	padding: var(--spacing-9) var(--spacing-6) var(--spacing-5);
	position: relative;

	@include mobile {
		padding: 0 var(--spacing-6);
		padding-bottom: calc(var(--spacing-5) + env(safe-area-inset-bottom));
		padding-top: env(safe-area-inset-top);
	}
}

.receipt-page-content {
	max-width: 684px;
	margin: 0 auto;
	width: 100%;
}

.service-card {
	border-radius: var(--spacing-2);
	box-shadow: 0px 4px 12px 0px var(--main-card-box-shadow-color);
	background-color: var(--color-neutral-white);
	overflow: hidden;
	cursor: pointer;
}

.service-header {
	@include d-flex(column, flex-start, center);
	width: 100%;
	// position: sticky;
	// height of header, should be css variable
	// top: calc(100px);
	margin-bottom: var(--spacing-6);

	@include mobile {
		// TODO add variable instead
		// top: calc(var(--spacing-3) + env(safe-area-inset-top));
		top: calc(-12px - env(safe-area-inset-top));
		margin-bottom: var(--spacing-4);
	}
}

.receipt-service-modal-header {
	top: 0;
	background-color: transparent;
}

html.smartbanner-show.smartbanner-margin-top {
	margin-top: 0 !important;

	.smartbanner-top {
		position: sticky;
		width: 100vw;
		left: 0;
	}

	.smartbanner-ios {
		.smartbanner-button {
			padding-left: 0;
		}
	}
}

.loader-dots {
	display: block;
	margin: 0 auto;
}

.report-bills-content {
	@include d-flex(column, flex-start, center, var(--spacing-4));
}

.services-tab-content {
	@include d-flex(column, flex-start, center);
	width: 100%;
	max-width: 688px;
}

.host-card-page-color-primary {
	@include host-card-page-color-primary;
}

.host-card-page-color-white-auth {
	@include host-card-page-color-primary;
	background-color: var(--color-neutral-white);

	button:first-child {
		svg path {
			stroke: #2e3131;
		}
	}
}

.host-card-page-color-white {
	@include host-card-page-color-primary;
	background-color: var(--color-neutral-white);

	button:first-child {
		svg path {
			stroke: #2e3131;
		}
	}

	& > ion-button {
		position: static;
		margin-top: auto;
		padding-top: var(--spacing-4);
	}
}

.auth-content {
	@include d-flex(column, flex-start, flex-start, var(--spacing-6));
	color: var(--color-primary-800);
	// + side paddings
	max-width: calc(448px + (var(--spacing-6) * 2));
	width: 100%;
	margin: 0 auto;
}

.data-hint-logo {
	background: var(--color-primary-500);
	width: 40px;
	height: 40px;
	border-radius: 50%;
	padding: 7px;
	cursor: pointer;
	flex-shrink: 0;

	img {
		filter: brightness(0) invert(1);
	}
}

.select-loader {
	margin-right: var(--spacing-3);
	color: inherit;
	width: 20px !important;
	height: 20px !important;
	z-index: 2;

	svg {
		width: 20px;
	}
}

.radabank-card-preview {
	@include d-flex(column, flex-start, flex-start);
	width: 100%;
	aspect-ratio: 327 / 184;
	border-radius: 10px;
	padding: var(--spacing-4);
	overflow: hidden;

	* {
		color: var(--color-neutral-white);
	}
}

.margin-top-auto {
	margin-top: auto;
}

.button-arrow-radabank {
	margin-right: auto;
	margin-bottom: var(--spacing-6);
}
