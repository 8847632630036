@import 'assets/styles/mixins';

.wrapper {
	@include d-flex(column, flex-start);
	gap: var(--spacing-5);
	background-color: var(--color-neutral-white);
	position: relative;
	width: 100%;
	height: 100%;
	padding-bottom: env(safe-area-inset-bottom);

	.header {
		@include d-flex(row, flex-start, center, var(--spacing-3));
	}

	button {
		margin-top: unset !important;
	}
}

.loader {
	margin: 0 auto;
	display: block;
}

.empty_state {
	color: var(--palette-text-disabled);
	text-align: center;
	display: block;
}

.row {
	
	li {
		padding: var(--spacing-2) var(--spacing-3);
		border-bottom: 1px solid #f3f3f3;
		overflow: hidden;
		white-space: normal;
	}

	&:last-of-type li {
		border: none;
	}
}