.modal {
    text-align: justify;
    
    .button {
        margin: var(--spacing-4) 0;
    }

    .list {
        list-style-type: '- ';
        padding-left: var(--spacing-3);
    }
}