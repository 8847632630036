@import 'assets/styles/mixins';
@import 'assets/styles/media';

.wrapper {
	background-color: var(--color-neutral-white);

	.details {
		@include d-flex(column);
		width: 100%;
		gap: var(--spacing-4);
		margin: var(--spacing-5) 0;

		&__item {
			@include d-flex(row, space-between, center, var(--spacing-4));
			width: 100%;
		}
	}

	ion-button {
		margin-top: var(--spacing-4);
	}
}
