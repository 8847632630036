@import './button.scss';
@import './modal.scss';

:root {
	--ion-color-primary: var(--color-primary-500);
	--ion-color-danger: var(--color-error-500);
	--ion-font-family: var(--font-family);
	font-family: var(--font-family);
}

ion-backdrop {
	opacity: 0.5;
	background: var(--color-primary-900);
}

ion-spinner {
	&.spinner-icon {
		width: 24px;
	}
}
