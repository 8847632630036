@import 'assets/styles/media.scss';
@import 'assets/styles/mixins.scss';

.header {
	@include d-flex(row, space-between, center, 20px);
	background-color: var(--color-neutral-layout-bg);
	min-height: 100px;
	max-height: 100px;
	padding: var(--spacing-6) var(--spacing-7);
	width: 100%;
	border-bottom: 1px solid var(--color-neutral-50);
	position: sticky;
	top: 0;
	z-index: 3;
	overflow: hidden;

	a {
		min-width: 140px;
	}

	&__logo {
		aspect-ratio: 148/36;
		width: 142px;
	}

	@include mobile {
		padding: 0 24px;
		background: none;
		min-height: auto;
	}
}
