@import 'assets/styles/mixins';
@import 'assets/styles/media';

.content {
	width: 100%;
	margin-bottom: var(--spacing-5);

	.title {
		width: 100%;
	}

	&__button {
		@include d-flex(row, center, center);
		position: absolute;
		padding: 4px;
		background: none;
		top: -2px;
		right: -48px;

		&__icon {
			width: 24px;
			height: 24px;

			&_small {
				width: 20px;
				height: 20px;
			}
		}
	}

	&__left {
		right: auto;
		left: -48px;
	}

	&__right {
		.content__button {
			flex-direction: row-reverse;
			position: static;
		}
	}
}

@include mobile {
	.content {
		@include d-flex(row, space-between, center, var(--spacing-2));

		.title {
			text-align: unset;
		}

		&__button {
			right: -10px;
		}

		&:has(.content__left) {
			.content__button {
				position: static;
			}
		}
	}
}
