@import '~assets/styles/mixins';

.content {
	@include d-flex(row, space-between, center, var(--spacing-3));
	width: 100%;
	border-radius: var(--spacing-2);
	border: 1px solid var(--color-primary-500);
	padding: var(--spacing-2) var(--spacing-3);

	&__info {
		@include d-flex(column);
		width: 100%;

		& > * {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	&.disabled {
		opacity: 0.5;
	}
}
