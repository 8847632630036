@import 'assets/styles/mixins';
@import 'assets/styles/media';

// GLIDE
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.theme';

.glide {
	&,
	&__track,
	&__slides {
		height: 100%;
	}

	&__slide {
		height: 100%;
		max-width: 685px;
		background-color: var(--color-neutral-white);
		border-radius: var(--main-card-border-radius);
		overflow: hidden;
		padding: var(--spacing-2) 0;
		padding: 1px;

		@include mobile {
			max-width: calc(100vw - 40px);
		}

		&__container {
			padding: 2px;
			height: 100%;
			overflow: hidden;
			position: relative;
		}

		&__scroll {
			max-width: calc(100vw - 40px);
			height: 100%;
			width: 100%;
			position: absolute;
			background-color: var(--color-neutral-white) !important;
		}
	}

	&__nav {
		@include d-flex(row, center, center);
		margin-bottom: 10px;
		margin-top: 10px;
		position: absolute;
		bottom: -32px;
		left: 50%;
		transform: translateX(-50%);
	}

	&__bullet {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: var(--color-primary-900);
		opacity: 0.2;

		&--active {
			transform: rotateZ(180deg);
			width: 16px;
			border-radius: 4px;
			background-color: var(--color-primary-900);
			opacity: 1;
			transition-property: transform width;
			transition-duration: 0.2s;
			transition-timing-function: ease-in;
		}
	}

	@include mobile {
		max-height: 100%;
		padding: 0;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		max-width: calc(100vw - 40px);
	}
}
