@import 'assets/styles/mixins';

.wrapper {
	background-color: var(--color-neutral-white);

	.header {
		gap: var(--spacing-6);
		margin-bottom: var(--spacing-6);
	}

	.list {
		@include d-flex(column);
		gap: var(--spacing-3);

		& > div {
			margin-bottom: 0;
		}
	}

	.button {
		margin-top: var(--spacing-9);
	}
}
