.wrapper {
	width: 100vw;
	height: 100vh;
	height: 100dvh;
	position: fixed;
	top: 0;
	left: 0;
	background-color: var(--color-neutral-white);
	z-index: 9999999;
}
