@import 'assets/styles/mixins';

ul.list {
	@include d-flex(row, flex-start, flex-start);
	width: 100%;

	&_blocked {
		pointer-events: none;
		touch-action: none;
	}

	.item {
		flex: 0 0 25%;
	}

	.item,
	.icon {
		@include d-flex(column, center, center, var(--spacing-1));
		cursor: pointer;
	}

	.icon {
		width: 44px;
		height: 44px;
		border-radius: 50%;
		background-color: var(--color-primary-500);
	}

	.label {
		color: var(--color-primary-900);
		text-align: center;
	}
}
