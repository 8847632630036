@import 'assets/styles/mixins';

div.layout {
	@include d-flex(column, flex-start, center);
	background-color: var(--color-primary-500);
	width: 100vw;
	height: 100vh;
	height: 100dvh;
	color: var(--color-neutral-white);
	padding-top: env(safe-area-inset-top);
	padding-bottom: calc(var(--spacing-4) + env(safe-area-inset-bottom));
}
