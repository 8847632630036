@import 'assets/styles/mixins';
@import 'assets/styles/media.scss';

.address_card {
	position: relative;
	border-radius: var(--main-card-border-radius);
	overflow: hidden;
	height: 100%;
	min-height: 120px;
	flex-basis: 30%;
	cursor: pointer;
	background-color: var(--apartment-map-bg);

	& > div {
		height: 100%;
	}

	&_single,
	&_inactive {
		cursor: auto;
	}

	&_single {
		flex-basis: auto;
	}

	&__description {
		@include d-flex(column, space-between);
		top: 0px;
		bottom: 0px;
		left: 0px;
		right: 0px;
		position: absolute;
		z-index: 2;
		padding: 10px 24px;

		div {
			@include d-flex(column);

			ion-button {
				width: 240px;
			}
		}
	}
}

@include mobile {
	.address_card {
		&__description {
			div {
				ion-button {
					width: 160px;
				}
			}
		}
	}
}

.button_menu {
	position: absolute;
	z-index: 2;
	right: var(--spacing-3);
	top: var(--spacing-3);

	button {
		border-color: #fff;
	}
}
