.wrapper {
	display: flex;
	width: 100%;
	position: relative;

	.scroll {
		position: absolute;
		height: 100%;
		left: calc(100% + 50px);

		&__button {
			position: sticky;
			top: 85vh;
			top: 85dvh;
            z-index: 1000;
            
            &.native {
                height: 40px;
                position: fixed;
            }
		}
	}
}

@media (max-width: 880px) {
	.wrapper {
		.scroll {
			left: calc(100% + 10px);
		}
	}
}

@media (max-width: 790px) {
	.wrapper {
		.scroll {
			left: unset;
			right: 10px;

            &__button {
                top: 81vh;
                top: 81dvh;
                right: 20px;
            }
		}
	}
}
