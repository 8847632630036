@import '~assets/styles/mixins';

div.page {
	max-height: 100vh;
	max-height: 100dvh;
	overflow: hidden;

	.content {
		@include d-flex(column);
		width: 100%;
		height: 100%;
		position: relative;
	}
}
