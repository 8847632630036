.button {
	&_next {
		margin-top: auto;
	}
}

#caption {
	display: inline-block;
	margin-top: var(--spacing-4);
}

.link {
	text-decoration: underline;
	display: inline;
	color: inherit;
}
