@import 'assets/styles/mixins.scss';

@include mobile-md {
	.title {
		font-size: var(--spacing-5)
	}
}

.list {
	@include d-flex(column);
	gap: var(--spacing-3);
	width: 100%;

	&__item {
		@include d-flex(row, flex-start, center, var(--spacing-3));
		border-radius: var(--spacing-2);
		padding: var(--spacing-2) var(--spacing-4);
		border: 1px solid var(--color-primary-500);

		.icon {
			@include d-flex(row, center, center);
			width: 40px;
			height: 40px;
			border-radius: 50%;
			background: var(--color-primary-500);
			flex-shrink: 0;

			img {
				height: 24px;
				object-fit: contain;
				filter: brightness(0) invert(1);
			}
		}

		&__title {
			white-space: break-spaces;
		}
	}
}

@include mobile-md {
	.list {
		&__item {
			&__title {
				font-size: 14px;
			}
		}
	}
}
