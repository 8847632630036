@import 'assets/styles/mixins.scss';
@import 'assets/styles/media.scss';

.wrapper {
	@include shadow2;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto repeat(3, max-content);
	background-color: var(--color-primary-500);
	height: 100%;
	border-radius: var(--spacing-4);
	padding: var(--spacing-4) 0;
	overflow: hidden;

	img {
		justify-self: center;
	}

	.list {
		&_links {
			@include d-flex(row, center, center, var(--spacing-3));
			width: 100%;
			margin-top: var(--spacing-6);
			padding: 0 var(--spacing-3);

			a {
				height: 51px;

				img {
					height: 100%;
				}
			}
		}
	}

	@include mobile {
		display: none;
		padding-top: 20px;

		img {
			order: 3;
		}

		.list {
			&_links {
				a {
					height: 32px;
				}
			}
		}
	}

	@media (max-height: 900px) {
		& > img {
			order: 3;
		}
	}
}
