@import 'assets/styles/mixins';

.wrapper {
	margin: 0 auto;

	.otp__wrapper {
		@include d-flex(row);
		gap: var(--spacing-3);
		overflow: hidden;

		&_invalid {
			input {
				border-color: var(--color-error-500) !important;
			}
		}
	}

	.input {
		max-width: 58px;
		width: 100%;
		height: 58px;
		border-radius: 8px;
		border: 1px solid var(--color-neutral-300);
		color: var(--color-primary-800);
		font-size: 20px;
		font-weight: 700;
		line-height: 160%;
		letter-spacing: 0.15px;
		outline: none;
		text-align: center;
		background: #fff;

		&:focus {
			user-select: none;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			border: 1px solid var(--color-primary-500);
		}

		&::selection {
			color: var(--color-primary-500);
			background-color: transparent;
		}

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		&[type='number'] {
			-moz-appearance: textfield;
		}
	}
}
