@import 'assets/styles/mixins';

.content {
	@include d-flex(column);
	max-width: 480px;
	width: 100%;
	// possibly need to try fit-content
	height: 100%;
	gap: var(--spacing-3);
	padding: var(--spacing-3) var(--spacing-6) var(--spacing-1) var(--spacing-6);

	&_blocked {
		opacity: 0.7;
	}
}
