@import 'assets/styles/mixins.scss';

.button {
	@include d-flex(row, center, center);
	border: 1.5px solid rgba(255, 255, 255, 0.5);
	border-radius: var(--spacing-3);
	cursor: pointer;
	background: transparent;
	max-width: 48px;
	width: 100%;
	aspect-ratio: 1 / 1;
	background-color: var(--color-primary-500);

	svg path {
		stroke: var(--color-neutral-white);
	}
}
