@import 'assets/styles/mixins';

:root {
	// 62px bottom navigation, spacing and inset top area is a layout padding top
	--bottom-navigation-height: calc(62px + var(--spacing-3) + env(safe-area-inset-top));
}

div.wrapper {
	@include d-flex(column);
	overflow: hidden;
	z-index: 9;
	background-color: var(--color-neutral-white);
	// padding-top: env(safe-area-inset-top);
	padding-top: var(--spacing-2);
	padding-bottom: env(safe-area-inset-bottom);
	min-height: calc(100vh - var(--bottom-navigation-height));
	min-height: calc(100dvh - var(--bottom-navigation-height));
	height: calc(100vh - var(--bottom-navigation-height));
	height: calc(100dvh - var(--bottom-navigation-height));
	box-shadow: var(--box-shadow-4);
	border-radius: var(--spacing-5) var(--spacing-5) 0px 0px;

	&.shadow_disabled {
		padding-top: env(safe-area-inset-top);

		box-shadow: none;
	}

	.header {
		@include d-flex(column, flex-end, center);
		padding: var(--spacing-5) 0 var(--spacing-3);
		height: 36px;
		position: relative;
		clip-path: inset(-100vmax -100vmax 0);

		&::after {
			content: '';
			display: block;
			width: 132px;
			height: 4px;
			border-radius: 100px;
			background-color: var(--color-neutral-100);
		}
	}

	.list {
		@include d-flex(column);
		gap: var(--spacing-5);
		overflow-y: auto;
		padding: 0 var(--spacing-6) var(--spacing-6);
		margin-top: var(--spacing-2);

		&__observer {
			margin-top: 0;
			height: 10px;
		}
	}

	.loader {
		position: relative;
		width: 100%;
		height: 10px;

		&__dots {
			@include absolute-center;
			width: 56px;
			height: 56px;
		}
	}

	.link {
		font-weight: 700;
	}

	.receipt_icon {
		position: absolute;
		left: 24px;
		top: 50%;
		transform: translateY(-50%);
		padding: 0;
	}
}
