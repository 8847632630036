@import 'assets/styles/mixins';

.content {
	@include d-flex(column);
	gap: var(--spacing-3);
	width: 100%;

	&__download {
		@include d-flex(row, space-between, center, var(--spacing-3));
		width: 100%;
	}
}
