.item {
    padding: var(--spacing-5);
    border-radius: var(--spacing-5);
    border: 1px solid transparent;
    width: 100%;

    & > span:not(:first-of-type) {
        width: 100%;
    }

    &.selected {
        border: 1px solid;
    }

    @media (max-width: 768px) {
        & > span:first-of-type {
            display: none;
        }
    }
}