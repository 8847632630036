@import 'assets/styles/mixins';

ion-button.button {
	@include typography(var(--color-error-500), 16px, 700, 150%);
	margin: 0 auto;

	svg {
		margin-right: 8px;
	}
}
