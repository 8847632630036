@import 'assets/styles/mixins';

.transaction {
	@include d-flex(column, unset, center, var(--spacing-8));
	width: 100%;
	text-align: center;
	height: calc(100vh - env(safe-area-inset-top) - var(--spacing-3));
	height: calc(100dvh - env(safe-area-inset-top) - var(--spacing-3));
	overflow: hidden;
	padding: 0 var(--spacing-6);
	padding-bottom: calc(var(--spacing-5) + env(safe-area-inset-bottom));
	padding-top: env(safe-area-inset-top);

	&__header {
		width: 100%;
	}

	&__icon {
		width: 32px;
		height: 32px;
		margin: 0 auto;
	}

	.rest {
		@include d-flex(row, space-between, center);
		width: 100%;
		margin-bottom: -20px;
	}

	&__destination_merchant {
		@include d-flex(column);
		gap: var(--spacing-3);
		width: 100%;
		padding-top: var(--spacing-3);
		border-top: 1px solid var(--color-neutral-600);
		flex-grow: 1;
		overflow-y: auto;
	}

	&__actions {
		width: 100%;
	}
}
