@import 'assets/styles/media.scss';
@import 'assets/styles/mixins';

.content {
	@include d-flex(column, null, null, 12px);
	max-width: 668px;
	width: 100%;
	margin: 0 auto;

	&.hidden {
		opacity: 0;
	}
}
