@import 'assets/styles/mixins';

.wrapper {
	@include d-flex(column);
	gap: var(--spacing-1);

	.range {
		@include d-flex(row, space-between, flex-start, var(--spacing-4));
	}

	.row {
		@include d-flex(row, space-between, center);
	}
}
