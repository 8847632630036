@import 'assets/styles/media';
@import 'assets/styles/mixins';

.wrapper {
	width: 100%;
	border-bottom: 1px solid var(--color-neutral-50);
	padding-bottom: var(--spacing-2);
	margin-bottom: var(--spacing-2);

	&:last-child {
		border: none;
		padding: 0;
		margin: 0;
	}

	.title {
		@include text-overflow(100%, break-spaces);
		margin-bottom: var(--spacing-2);
	}

	.row {
		@include d-flex(row, space-between, center);
		gap: var(--spacing-2);
		align-items: center;
		padding: var(--spacing-2);

		&:nth-of-type(2n + 1) {
			background-color: var(--color-primary-50);
		}

		&__input {
			max-width: 124px;

			fieldset {
				top: 0;
			}
		}
	}

	@media (max-width: 380px) {
		.row {
			&_editable {
				@include d-flex(column);
				align-items: flex-start;
			}

			&__input {
				max-width: 100%;
				margin-top: var(--spacing-2);
			}
		}
	}
}
