@import '~assets/styles/mixins';

div.page {
	.content {
		@include d-flex(column);
		width: 100%;
		margin-bottom: auto;
	}

	& > ion-button {
		margin-top: var(--spacing-6);
	}

	.card {
		width: auto;
	}
}
