@import 'assets/styles/mixins';

.list {
	@include d-flex(column);
	gap: var(--spacing-3);
	height: 100%;
	padding: var(--spacing-2) var(--spacing-4);

	.item {
		@include d-flex(row, space-between, center, var(--spacing-3));
		box-shadow: var(--box-shadow);
		padding: var(--spacing-2) var(--spacing-4);
		border-radius: 10px;
	}

	.button {
		&_delete {
			svg {
				width: 16px;
				height: 16px;
			}
		}
	}
}
