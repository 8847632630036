.button {
	width: 32px;
	height: 32px;

	.icon {
		width: 20px;
		height: 20px;

		&_white {
			path {
				stroke: var(--color-neutral-white) !important;
			}
		}
	}
}
