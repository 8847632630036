@import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/react/css/normalize.css';
@import '@ionic/react/css/structure.css';
@import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/react/css/padding.css';
@import '@ionic/react/css/float-elements.css';
@import '@ionic/react/css/text-alignment.css';
@import '@ionic/react/css/text-transformation.css';
@import '@ionic/react/css/flex-utils.css';
@import '@ionic/react/css/display.css';

/* Swiper */
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'swiper/css/scrollbar';

// Common global
// TODO: rework with monorepo
@import './assets/styles/global.scss';
@import './assets/styles/css-variables.scss';
@import './assets/styles/global.scss';
@import './assets/styles/ionic/main.scss';

@import './assets/fonts/fonts.css';

ion-content {
	--background: transparent;
	overscroll-behavior: none
}

body {
	background: #fff;
	color: var(--color-primary-800);
}

body.scanner-active {
	opacity: 0;
	background: transparent;
	--background: transparent;
	--ion-background-color: transparent;
}

