@import 'assets/styles/media.scss';

.stepper-wrapper {
	position: relative;
	background-color: white;
	display: flex;
	gap: 8px;
	flex: 1;
	width: 100%;
}

.step {
	text-align: center;
	flex: 1;
	height: 10vh;
	font-size: 14px;
	font-weight: 700;
	color: #2e3131;
	height: min-content;
	white-space: nowrap;
	max-width: 150px;

	&_unactive {
		opacity: 0.7;
	}
	&__title {
		margin: 0;
	}
}

@include mobile {
	.step__title {
		display: none;
	}
}
