@mixin tablet {
	@media (max-width: 1024px) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: 1025px) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: 1024px) {
		@content;
	}
}

@mixin mobile-md {
	@media (max-width:374px) {
		@content
	}
}

@mixin mobile-small {
	@media (max-width: 319px) {
		@content;
	}
}

@mixin mobile-small-height {
	@media (max-height: 668px) {
		@content;
	}
}