@import 'assets/styles/mixins';
@import 'assets/styles/media';

.details {
	@include d-flex(column, flex-start, center, var(--spacing-5));

	&__item {
		@include d-flex(row, space-between, flex-start, var(--spacing-3));
		width: 100%;

		a {
			text-decoration: underline;
		}
	}

	&__contacts {
		@include d-flex(row, flex-end);
		gap: var(--spacing-2);
		white-space: nowrap;
		flex-wrap: wrap;
	}
}
