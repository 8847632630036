@import 'assets/styles/mixins';
@import 'assets/styles/media.scss';

.wrapper {
	@include d-flex(column, null, center);
	font-weight: bold;
	font-size: 35px;
	padding-top: 30vh;

	.error {
		&__info {
			@include typography(var(--color-primary-800), 28px, 700, 133.4%);
			margin-top: 32px;
			text-align: center;
			margin-bottom: 68px;
		}

		&__icon {
			background: var(--color-error-50);
			border-radius: 50%;
			min-width: 50px;
			min-height: 50px;
		}

		&__action {
			min-width: 320px;
		}
	}
}

.profile {
	padding-top: 40vh;
}

@include mobile {
	.wrapper {
		.error {
			&__action {
				position: absolute;
				bottom: 15vh;
				min-width: auto;
			}
		}
	}
}
