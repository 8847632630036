ion-toggle {
	height: 35px;
	margin-left: 5px;
	z-index: 0;
	/* Required for iOS handle to overflow the height of the track */
	overflow: visible;
	contain: none;

	&::part(track) {
		background-color: rgba(77, 124, 121, 0.38);
	}

	&::part(handle) {
		background: var(--color-neutral-50);
	}

	&:not(.toggle-checked)::part(track) {
		background: var(--color-neutral-300);
	}

	&.toggle-checked::part(handle) {
		background: var(--color-primary-500);
	}
}
