@import 'assets/styles/mixins';
@import 'assets/styles/media.scss';

.header {
	&__description {
		color: var(--color-neutral-500);
	}
}

.content {
	&__empty {
		@include d-flex(column, flex-start, center);
		margin-top: 10vh;
	}
}

.detail {
	@include d-flex(row, space-between, center, var(--spacing-3));
	flex-wrap: nowrap;

	padding: 0 var(--spacing-4);
	margin-top: var(--spacing-4);
}
