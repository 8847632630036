@import 'assets/styles/mixins';

.wrapper {
	@include d-flex(column, space-between, center, var(--spacing-4));
	height: 100%;

	.info {
		@include d-flex(column, flex-start, center, var(--spacing-9));
		margin: auto;
	}
}
