@import 'assets/styles/media.scss';
@import 'assets/styles/mixins';

.wrapper {
	width: 100%;
	border-bottom: 1px solid var(--color-neutral-50);

	&:last-child {
		border: none;
	}

	button {
		@include typography(var(--color-primary-500), 16px, 700);
		height: 52px;
		white-space: nowrap;

		svg path {
			stroke: var(--color-primary-500);
			stroke-width: 1.5;
		}
	}

	.content {
		@include d-flex(row, null, flex-start, 12px);
		padding: var(--spacing-2) 0;

		&__title {
			@include text-overflow(100%, break-spaces);
		}

		&__info {
			flex: 1;

			fieldset {
				top: 0;
			}

			.tariff__list {
				@include d-flex(column);
				width: 100%;
				gap: var(--spacing-2);
			}

			& > span {
				color: var(--color-neutral-500);
			}

			&_accented {
				color: var(--color-error-500);
			}

			&_positive {
				color: var(--color-success-500);
			}
		}
	}
}

@include desktop {
	.service-bill {
		h3 {
			width: auto;
		}
	}
}
