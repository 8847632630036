@import 'assets/styles/mixins';
@import 'assets/styles/media.scss';

header.wrapper {
	& > div:first-child {
		@include d-flex(row, flex-start, center, var(--spacing-2));
		width: 100%;
		margin-bottom: var(--spacing-4);
	}

	.title {
		display: block;
		width: 100%;
		text-align: center;
	}

	@include mobile {
		& > div:first-child {
			margin-bottom: var(--spacing-3);
		}
	}
}
