@import 'assets/styles/mixins';
@import 'assets/styles/media';

.wrapper {
	display: grid;
	grid-template-rows: auto 1fr;
	height: 100%;
	overflow: hidden;

	div.content {
		max-width: 1326px;
		width: 100%;
		padding-bottom: var(--spacing-9);
		overflow: hidden;
		display: grid;
		grid-template-columns: 43.5% 1fr;
		grid-template-rows: 100%;
		gap: var(--spacing-9);
		align-items: flex-start;
	}

	.error {
		@include d-flex(column);
		gap: var(--spacing-4);

		a {
			display: none;
		}
	}

	@include mobile {
		grid-template-rows: auto 1fr;

		div.content {
			grid-template-columns: 1fr;
			padding: 0;
		}

		.error {
			margin-top: var(--spacing-4);

			&__link {
				display: block;

				&_hidden {
					display: none;
				}
			}
		}
	}
}
