@import 'assets/styles/mixins';
@import 'assets/styles/media.scss';

.header {
	@include d-flex(row, space-between, center, var(--spacing-2));
	width: 100%;
	margin-bottom: var(--spacing-4);

	.button__back {
		align-self: flex-start;
	}

	.text {
		@include d-flex(column, flex-start, center);
		flex: 1 1 100%;
		text-align: center;
		width: 100%;
		overflow: hidden;

		span {
			width: 100%;
		}
	}
}
