@import 'assets/styles/mixins';

.content {
	@include d-flex(column);
	height: 100%;

	&__submission {
		margin-top: var(--spacing-9);
	}

	&__close {
		width: 100%;
		margin-top: calc(var(--spacing-9) + var(--spacing-3));
		margin-bottom: var(--spacing-6);
	}

	&__submit {
		margin-top: auto;
	}
}
