@import 'assets/styles/mixins';

div.wrapper {
	background: linear-gradient(
			to right,
			rgba(var(--color-primary-500-rgb), 0.4) 20%,
			rgba(var(--color-primary-500-rgb), 0.4)
		),
		url('~assets/images/host-card-bg.png');
	background-size: cover;
	padding-top: 56px;
	position: relative;

	&.blocked {
		opacity: 0.7;
	}

	&::before {
		content: '';
		position: absolute;
		top: var(--spacing-4);
		left: var(--spacing-4);
		max-width: 105px;
		width: 100%;
		aspect-ratio: 96/29;
		background: no-repeat center / contain url('~assets/images/host-logo-card.svg');
	}

	&::after {
		content: '';
		position: absolute;
		bottom: var(--spacing-4);
		right: var(--spacing-4);
		max-width: 65px;
		width: 100%;
		aspect-ratio: 247/150;
		background: no-repeat center / contain url('~assets/icons/mastercard.svg');
	}
}
