@import 'assets/styles/mixins';
@import 'assets/styles/media.scss';

@include mobile {
	#content {
		padding-left: 0;
		padding-right: 0;
	}
}

.header {
	padding: 0 var(--spacing-6);
}

.list {
	@include d-flex(column);
	gap: var(--spacing-5);
	overflow-y: auto;
	padding: 0 var(--spacing-6);
	margin-top: var(--spacing-2);
	width: 100%;
}
