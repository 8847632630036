@import 'assets/styles/mixins';

.wrapper {
	.row {
		@include d-flex(row, space-between, center);
        width: 100%;
	}

	.info {
		@include d-flex(column, flex-start, center, var(--spacing-3));
	}
}
