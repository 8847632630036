.wrapper {
	width: 100%;
	height: 100%;

	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999;

	ion-spinner {
		--color: #fff;

		width: 50px;
		height: 50px;

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
	}
}
