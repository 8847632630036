@import 'assets/styles/mixins';

.wrapper {
	position: relative;
	width: 100%;
	height: 1px;
	margin-top: 28px;

	.loader {
		@include absolute-center;
		width: 56px;
		height: 56px;
	}

	.observer {
		height: 100%;
		display: hidden;
	}
}
