@import 'assets/styles/media.scss';
@import 'assets/styles/mixins';

.wrapper {
	border-bottom: 1px solid var(--color-neutral-50);

	&:last-child {
		border: none;
	}

	button {
		@include typography(var(--color-primary-500), 16px, 700);
		height: 52px;
		white-space: nowrap;

		svg path {
			stroke: var(--color-primary-500);
			stroke-width: 1.5;
		}
	}

	.content {
		@include d-flex(row, null, flex-start, 12px);
		padding: var(--spacing-2) 0;

		&__title {
			@include d-flex(row, flex-start, center, var(--spacing-3));
			width: 100%;
			margin-bottom: 4px;

			.text {
				@include text-overflow(100%, break-spaces);
			}

			svg {
				cursor: pointer;
			}
		}

		&__info {
			flex: 1;

			& div {
				@include typography(var(--color-primary-800), 16px, 400);

				fieldset {
					top: 0;
				}
			}

			h3 {
				@include text-overflow(100%, break-spaces);
				@include typography(var(--color-primary-800), 14px, 700);
				-webkit-line-clamp: 2;
				display: -webkit-box;
				-webkit-box-orient: vertical;
			}

			& > span {
				color: var(--color-neutral-500);
			}

			&_accented {
				color: var(--color-error-500);
			}

			&_positive {
				color: var(--color-success-500);
			}
		}
	}
}

.list__details {
	@include d-flex(column, flex-start, center, var(--spacing-5));
	margin-bottom: var(--spacing-5);
}

@include desktop {
	.service-bill {
		h3 {
			width: auto;
		}
	}
}
