@import 'assets/styles/mixins';
@import 'assets/styles/media';

.card {
	@include d-flex(row, flex-start, center, var(--spacing-3));
	width: 100%;
	overflow: hidden;

	&__logos {
		@include d-flex(row, flex-start, center, var(--spacing-3));
		flex-shrink: 0;
	}

	&__question {
		flex-shrink: 0;
	}

	&__icon {
		width: 40px;
		height: 40px;
		flex-shrink: 0;

		img {
			object-fit: contain;
			width: 100%;
		}

		&_category {
			border-radius: 50%;
			padding: 8px;
			background-color: var(--color-primary-500);

			img {
				filter: brightness(0) invert(1);
			}
		}

		&_gas {
			background-color: #36c8fa;
		}

		&_energo {
			background-color: #ffd66c;
		}
	}

	&__info {
		@include d-flex(column);
		gap: 4px;
		width: 100%;
		overflow: hidden;

		p:last-of-type {
			white-space: break-spaces;
		}

		.title {
			@include d-flex(row, flex-start, center, var(--spacing-3));

			&__text {
				display: -webkit-box;
				white-space: break-spaces;
				text-overflow: ellipsis;
				overflow: hidden;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				// width: 100%;
			}

			svg {
				flex-shrink: 0;
			}
		}
	}
}

.details {
	@include d-flex(column, flex-start, center, var(--spacing-5));

	&__item {
		@include d-flex(row, space-between, flex-start, var(--spacing-3));
		width: 100%;

		a {
			text-decoration: underline;
		}
	}

	&__contacts {
		@include d-flex(row, flex-end);
		gap: var(--spacing-2);
		white-space: nowrap;
		flex-wrap: wrap;
	}
}
