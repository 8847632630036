@import 'assets/styles/mixins';

.content {
	@include d-flex(column);
	gap: var(--spacing-3);
	width: 100%;
	padding: var(--spacing-4);
	border-radius: var(--spacing-2);
	box-shadow: var(--box-shadow-1);
	background-color: var(--color-neutral-white);
}
