@import 'assets/styles/mixins';

.list {
	@include d-flex(column);
	gap: var(--spacing-5);
	overflow-y: auto;
	padding: 0 var(--spacing-6);
	margin-top: var(--spacing-2);
	width: 100%;
}
