@import 'assets/styles/mixins';

.list {
	@include d-flex(column);
	width: 100%;
	gap: var(--spacing-4);
	margin: var(--spacing-7) 0 !important;

	li {
		@include d-flex(row, flex-start, center, var(--spacing-3));

		svg path {
			stroke: var(--color-neutral-white);
		}
	}
}
