@import 'assets/styles/media';

ion-modal {
	--width: auto;
	--height: auto;
	--max-height: calc(90vh - 200px);
	--border-radius: var(--spacing-5) var(--spacing-5) 0 0;

	& > .ion-page {
		overflow: visible;
	}

	&::part(handle) {
		display: none;
	}

	&::part(content) {
		padding: var(--spacing-6) 60px;
		box-sizing: border-box;
		box-shadow: var(--box-shadow-3);
		top: 200px;
		position: absolute;
		border-radius: var(--spacing-2);
		width: 448px;
		bottom: unset;
		overflow-y: auto;
	}

	@include mobile {
		--max-height: calc(100vh - env(safe-area-inset-top));

		&::part(content) {
			border-radius: var(--spacing-5) var(--spacing-5) 0 0;
			width: 100%;
			padding: var(--spacing-6);
			bottom: 0;
			top: unset;
		}

		&:not([initial-breakpoint])::part(content) {
			border-radius: var(--spacing-2);
			margin: 0 var(--spacing-6);
			bottom: unset;
			width: auto;
		}

		&.full-height-modal {
			--height: 100vh;
			--height: 100dvh;
			--min-height: 100%;
			overflow-y: hidden;
			margin-top: env(safe-area-inset-top);

			&::part(content) {
				border-radius: 0;
				overflow-y: auto;
				padding: 0;
			}

			& > div:first-of-type {
				// height: 100vh;
				// height: 100dvh;

				& > * {
					padding: var(--spacing-6);
					// height: 100vh;
					// height: 100dvh;
					overflow: auto;

					// & > * {
					// 	padding: var(--spacing-6);
					// }
				}
			}
		}

		& > div:first-of-type {
			height: auto;
			min-height: 100%;
		}
	}
}

.modal-actions-row {
	@include d-flex(column, center, center, var(--spacing-4));
	width: 100%;
}

.modal-actions-column {
	@include d-flex(row, center, center, var(--spacing-4));
	width: 100%;

	@include mobile {
		flex-direction: column;
	}
}
