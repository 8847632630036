@import 'assets/styles/mixins.scss';

.item {
	@include d-flex(row, flex-start, center, var(--spacing-3));
	border-radius: var(--spacing-2);
	padding: var(--spacing-2) var(--spacing-4);
	border: 1px solid var(--color-primary-500);

	&__icon {
		@include d-flex(row, center, center);
        width: 40px;
		height: 40px;
		border-radius: 50%;
		background: var(--color-primary-500);
		flex-shrink: 0;

		img {
			height: 24px;
			object-fit: contain;
			filter: brightness(0) invert(1);
		}
	}

	&__title {
		white-space: break-spaces;   
	}

    .question {
        display: inline;
        margin-left: var(--spacing-1);
        margin-bottom: calc(-1 * var(--spacing-2));
    }
}

@include mobile-md {
	.item {
		&__title {
			font-size: 14px;
		}
	}
}
