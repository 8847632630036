.wrapper {
  & > button {
    position: absolute;
    border-radius: var(--spacing-3);
    z-index: 1;
    padding: var(--spacing-2);
    top: calc(env(safe-area-inset-top) + 10px);
    left: 25px;
    border: 1px solid rgba(255, 255, 255, 0.3);

    & svg path {
      stroke: var(--color-neutral-white);
    }
  }

  &__content {
    padding-top: 20px;
  }
}
