@import 'assets/styles/media';

.wrapper {
	--max-height: 90vh;
	--min-height: 90vh;
	overflow-y: auto;

	&::part(content) {
		max-width: 1440px;
		width: 80vw !important;
		top: unset;
	}

	&.service {
		&::part(content) {
			width: 575px !important;
		}
	}

	@include mobile {
		&, &.service {
			&::part(content) {
				width: 100vw !important;
			}
		}
	}
}
