@import 'assets/styles/mixins';
@import 'assets/styles/media';

.content {
	width: 100%;
	margin: 0 auto;

	&__toolbar {
		@include d-flex(row, space-between, center);
		padding: 10.5px;
		margin-top: 24px;

		button {
			padding: 0;
			min-height: max-content;

			svg {
				width: 24px;
				height: 24px;
				cursor: pointer;
			}

			&:disabled svg path {
				stroke: var(--color-primary-50);
			}

			&:last-child {
				transform: rotateY(180deg);
			}
		}

		span {
			@include typography(var(--neutral-10-o), 16px, 700, 150%, 0.15px);
		}
	}

	&__header {
		position: relative;
	}

	&__list {
		display: grid;
		grid-template-columns: auto auto auto;
		gap: 12px;
		margin-top: 12px;

		.month {
			@include d-flex(row, center, center);
			@include typography(var(--color-primary-800), 16px, 400, 150%, 0.15px);
			width: 100%;
			padding: 10px;
			text-transform: capitalize;
			text-align: center;
			border-radius: 40px;

			&_selected {
				background-color: var(--color-primary-500);
				color: var(--color-neutral-white);
				transition-property: background-color, color;
				transition-duration: 0.2s;
			}
		}
	}

	.button {
		cursor: pointer;
		margin-top: 40px;

		&:disabled {
			cursor: default;
		}
	}
}

@include mobile {
	.content {
		&__toolbar {
			margin-top: 32px;
		}

		.button {
			margin-top: 40px;
		}
	}
}
