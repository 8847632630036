@import 'assets/styles/mixins';

.info {
	@include d-flex(column, flex-start, center, var(--spacing-3));
	width: 100%;
}

.row {
	@include d-flex(row, space-between, center);
    width: 100%;
}
