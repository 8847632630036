@import 'assets/styles/mixins';

.form {
	@include d-flex(column);
	height: 100%;

	&__button {
		margin-top: auto;
	}
}
