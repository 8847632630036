@import 'assets/styles/media.scss';
@import 'assets/styles/mixins';

// TODO: should change values to spacing
div.wrapper {
	padding-bottom: var(--spacing-9);
	overflow: hidden;

	.content {
		box-shadow: 0px 8px 16px 0px rgba(51, 75, 78, 0.12);
		max-width: 684px;
		width: 100%;
		height: 100%;
		overflow: hidden;
		border-radius: var(--spacing-4);
		display: grid;
		grid-template-rows: max-content 1fr;
		padding-top: env(safe-area-inset-top);

		.receipt {
			@include d-flex(column);
			width: 100%;
			overflow: hidden;
			border-radius: var(--spacing-5) var(--spacing-5) 0px 0px;
			margin-top: -20px;
			z-index: 2;
			background-color: var(--color-neutral-white);

			&__header {
				@include d-flex(row, space-between, center, var(--spacing-3));
				margin-bottom: var(--spacing-3);
			}

			&__list {
				@include d-flex(column, flex-start, center, 24px);
				width: 100%;
				height: 100%;
				overflow: auto;
				padding: 24px 24px 20px 24px;
				flex-grow: 1;

				&_text {
					display: flex;
					color: var(--color-primary-500);
					margin: 0 auto;

					&:hover {
						background-color: transparent;
					}
				}
			}

			&__item {
				width: 100%;

				ion-button {
					display: block;
					margin: 0 auto;
				}
			}

			&__header {
				@include d-flex(row, space-between, center, 24px);
				width: 100%;
			}
		}
	}

	.button {
		width: 100%;
		box-shadow: 0px 8px 20px 0px rgba(51, 75, 78, 0.42);
		border-radius: 20px 20px 0px 0px;
		padding: 24px;
		background-color: var(--color-neutral-white);
		position: sticky;
		bottom: 0;
		flex-grow: 0;
	}

	@include mobile {
		padding-bottom: 0;

		.content {
			border-radius: 0;
			height: 100vh;
			height: 100dvh;
			position: fixed;
			max-width: none;
			top: 0;
			left: 0;
			z-index: 1;
		}
	}
}
