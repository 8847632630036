@import 'assets/styles/mixins';
@import 'assets/styles/media';

.header {
	@include d-flex(row, space-between, flex-start, var(--spacing-3));
	width: 100%;

	@include mobile {
		flex-direction: column-reverse;
	}

	&__status {
		@include d-flex(row, flex-start, center, var(--spacing-3));

		@include mobile {
			width: 100%;
			justify-content: space-between;
		}
	}
}
