.button {
    position: absolute;
    width: 40px;
    aspect-ratio: 1;
    bottom: 50px;
    right: 50px;
    
    &, &:hover {
        background-color: var(--color-primary-500);
    }

	.icon {
		transform: rotate(90deg);
        
        path {
            stroke: var(--palette-common-white)
        }
	}
}
