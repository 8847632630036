@import 'assets/styles/mixins';

.list {
	@include d-flex(column);
	width: 100%;
	gap: var(--spacing-5);

	.input {
		input {
			font-weight: normal;
			font-size: 16px;
			height: 44px;
		}
	}
}
