@import 'assets/styles/mixins';
@import 'assets/styles/media';

.description {
	@include d-flex(column, null, center);
	@include text-overflow;
}

.content {
	@include d-flex(column, null, null, 24px);
	text-align: center;

	& ion-button {
		margin-top: 40px;
	}

	& > svg {
		position: absolute;
		top: -12px;
		right: -48px;
		cursor: pointer;
	}

	& > div:nth-of-type(3) {
		width: 100%;
		margin-top: 16px;

		ion-button {
			margin-top: 12px;
		}
	}
}

@include mobile {
	.description {
		align-items: flex-start;
	}

	.content {
		text-align: left;
		// height: 100%;

		& ion-button,
		& > div:nth-of-type(3) {
			margin-top: auto;
		}
	}

	.pinWeb {
		height: auto;
	
		&__text {
			text-align: center;
		}
	}
}

.pinWeb {
	@include d-flex(column, flex-start, flex-start);
	opacity: 0.5;

	&__text {
		white-space: break-spaces;
	}
}