@import 'assets/styles/media.scss';
@import 'assets/styles/mixins.scss';

.wrapper {
	@include d-flex(row, space-between, center, var(--spacing-5));
	width: calc(100% - (var(--spacing-6) * 2));
	position: absolute;
	left: 50%;
	transform: translate(-50%);
	top: 24px;
	z-index: 2;

	.button {
		@include d-flex(row, center, center);
		padding: var(--spacing-3);
		border: 1px solid rgba(255, 255, 255, 0.3);
		border-radius: var(--spacing-3);
		cursor: pointer;

		svg path {
			stroke: var(--color-neutral-white);
		}
	}
}
