@import '~assets/styles/mixins';

.content,
form {
	height: 100%;

	form {
		@include d-flex(column, space-between, flex-start, var(--spacing-4));
	}
}
