@import 'assets/styles/mixins';

.content {
	@include d-flex(column);
	gap: var(--spacing-1);
	width: 100%;

	&__row {
		@include d-flex(row, flex-start, center, var(--spacing-1));
		width: 100%;
		overflow: hidden;

		& > * {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}
