@import '~assets/styles/mixins';

.wrapper,
.footer {
	@include d-flex(column);
	gap: var(--spacing-6);
	width: 100%;

	.footer {
		margin-top: auto;
	}
}
