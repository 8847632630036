@import 'assets/styles/mixins';

.content {
    @include d-flex(column);
	height: 100%;
	width: 100%;
}

.wrapper {
    height: 100%;
    width: 100%;

    .form {
        @include d-flex(column);
        height: 100%;
        width: 100%;

        &__content {
            @include d-flex(column, flex-start, center, var(--spacing-5));
            margin-bottom: var(--spacing-5);
        }

        .controls {
            margin-top: auto;
        }
    }

}