@import 'assets/styles/mixins.scss';

.header {
    display: inline-flex;
    align-items: center;
    width: 100%;

    .icon {
        margin-left: var(--spacing-1);
        margin-bottom: -5px;
        display: inline;
    }
}
