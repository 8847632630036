@import 'assets/styles/mixins';

.wrapper {
	@include d-flex(column, flex-start);
	gap: var(--spacing-5);
	background-color: var(--color-neutral-white);
	position: relative;
	width: 100%;
	height: 100%;

	.header {
		@include d-flex(row, flex-start, center, var(--spacing-3));
	}

	.list {
		height: 100%;
		padding: 0;
		overflow-y: auto;

		li {
			padding: var(--spacing-2) var(--spacing-3);
			border-bottom: 1px solid #f3f3f3;
			overflow: hidden;
			white-space: normal;

			&:last-of-type {
				border: none;
			}
		}
	}

	button {
		margin-top: unset !important;
	}
}

.clear {
	position: relative;
}

.empty_state {
	color: var(--palette-text-disabled);
}
