@import 'assets/styles/mixins';

.content {
	@include d-flex(row, flex-start, flex-start);
	gap: var(--spacing-3);
	overflow: hidden;
	padding: var(--spacing-3) 0;

	&__icon {
		width: 24px;
		object-fit: contain;
		flex-shrink: 0;
	}

	&__info {
		@include d-flex(column);
		gap: var(--spacing-1);
		width: 100%;
		white-space: normal;

		& > * {
		}
	}
}
