@import 'assets/styles/mixins';

.wrapper {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: var(--spacing-4);
	width: 100%;

	ion-button {
		--border-radius: var(--spacing-3);
		--background: #ffffff12 !important;
		--box-shadow: none;
		--padding-top: 12px;
		height: 52px;
		font-size: 20px;
		font-weight: 700;
		color: var(--color-neutral-white);

		&.invisible {
			visibility: hidden;
			opacity: 0;
		}

		&:last-child {
			--background: transparent !important;
		}
	}

	&.color_primary {
		ion-button {
			--background: var(--color-primary-50) !important;
			color: var(--color-primary-500);
		}

		svg path {
			stroke: var(--color-primary-500);
		}
	}
}
