@import '~assets/styles/mixins';

.content {
	@include d-flex(column);
	gap: var(--spacing-5);
	width: 100%;
	height: 100%;

	.row {
		@include d-flex(row, flex-start, center, var(--spacing-5));

		& > * {
			margin: 0;
		}
	}

	.column {
		@include d-flex(column);
		gap: var(--spacing-1);

		&_big {
			gap: var(--spacing-3);
		}
	}
}

.button_submit {
	position: sticky;
	bottom: 0;
}
