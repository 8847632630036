@import 'assets/styles/media.scss';

.list {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: var(--spacing-6);

	@include mobile {
		grid-template-columns: repeat(2, 1fr);
	}
}
