@import '~assets/styles/mixins';

.content {
	@include d-flex(column);
	gap: var(--spacing-6);

	.button {
		border-radius: var(--spacing-2);
		border: 1px solid var(--color-primary-500);
		padding: var(--spacing-4);

		&__info {
			@include d-flex(column);
		}
	}
}
