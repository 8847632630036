@import 'assets/styles/mixins';

.wrapper {
	@include d-flex(column);
	gap: var(--spacing-3);
	padding: var(--spacing-4);
	border-radius: var(--spacing-2);
	background: var(--color-neutral-white);
	box-shadow: 0 4px 12px 0 rgba(51, 75, 78, 0.08);

	&__title {
		@include typography(var(--color-neutral-500), 16px, 700, 150%, 0.15px);
	}

	& > div:last-of-type {
		border-bottom: none;
	}
}
