@import 'assets/styles/mixins.scss';

.actions {
	@include d-flex(column, flex-start, center, var(--spacing-3));
	width: 100%;

	ion-button,
	a {
		display: block;
		width: 100%;
	}
}
