@import 'assets/styles/mixins';
@import 'assets/styles/media';

@include slider-pagination(var(--color-primary-900), auto, 10px);

.slider,
.title {
	max-width: 932px;
	width: 100%;

	@include mobile {
		display: none !important;
	}
}

.title {
	font-size: 18px;
	text-align: center;
	margin: 0 auto;
}

.slider {
	position: relative;
	padding: var(--spacing-3) var(--spacing-3) 88px !important;

	&__nav {
		@include d-flex(row, space-evenly, center);
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 0 20%;
		z-index: 11;

		ion-button {
			width: max-content;
		}
	}

	&__slide {
		background-color: var(--color-neutral-white);
		border-radius: var(--main-card-border-radius);
		box-shadow: var(--box-shadow-2);
		overflow: hidden;
		padding: var(--spacing-7);
	}

	&__icon {
		@include d-flex(column, center, center);
		width: 60px;
		height: 60px;
		background-color: var(--color-primary-50);
		border-radius: 50%;
		margin: 0 auto;
		margin-bottom: var(--spacing-4);
	}
}

div.custom_wrapper {
	align-items: center;
}
