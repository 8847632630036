@import 'assets/styles/mixins';

.form {
	@include d-flex(column);
	gap: var(--spacing-6);
	height: 100%;

	&__button {
		margin-top: auto;
	}
}
