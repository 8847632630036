.switch {
	width: 100%;

	&__content > div:last-child {
		overflow: visible;
	}

	ion-toggle {
		pointer-events: none;
	}
}
