@import '~assets/styles/mixins';

div.page {
	.content {
		@include d-flex(column);
		width: 100%;
		margin-bottom: auto;

		&__card {
			margin-bottom: var(--spacing-5);
			width: auto;
		}
	}

	& > ion-button {
		margin-top: var(--spacing-6);
	}
}
