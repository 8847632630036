@import 'assets/styles/mixins';

.content {
	@include d-flex(row, flex-start, center, var(--spacing-4));

	&__img {
		width: 36px;
		height: 36px;
		object-fit: cover;
		border-radius: 50%;
	}

	&__info {
		@include d-flex(column);
	}
}
