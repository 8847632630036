@import '~assets/styles/mixins';

.content {
	@include d-flex(row);
	gap: var(--spacing-1);
	padding: var(--spacing-6) 0;
	width: 100%;

	.icon {
		width: 24px;
		flex-shrink: 0;
	}

	&_error {
		.icon {
			path {
				stroke: var(--color-error-500);
			}
		}
	}
}
