@import 'assets/styles/mixins';

.wrapper {
	@include full-screen-page;
	display: grid;
	grid-template-rows: 1fr auto auto;
	gap: var(--spacing-6);
	overflow: hidden;
	padding-top: env(safe-area-inset-top);
	padding-bottom: calc(var(--spacing-4) + env(safe-area-inset-bottom));

	& > div:not(:first-child) {
		padding: 0 var(--spacing-6);
	}

	.header {
		@include d-flex(column, flex-end);
		position: relative;

		&__img {
			@include absolute-center;
			@include d-flex(column, center, center);
			height: 64%;
			max-height: 240px;
			aspect-ratio: 0.83;
			margin: 0 auto;
			border-radius: 50%;
			overflow: visible;
			width: 100%;

			svg {
				height: 80%;
				margin: 0 auto;

				path {
					fill: var(--color-primary-300);
				}
			}

			&::after {
				@include absolute-center;
				display: block;
				content: '';
				border-radius: 50%;
				height: 100%;
				aspect-ratio: 1;
				background-color: rgba(255, 255, 255, 0.08);
				box-shadow: 0px 0px 0px 30px rgba(255, 255, 255, 0.03), 0px 0px 0px 65px rgba(255, 255, 255, 0.03);
			}
		}

		&__list {
			@include d-flex(row, center, center, 25px);
			margin-top: auto;
			position: relative;

			div {
				position: relative;
				border-radius: 50%;
				box-shadow: 0px 12px 20px 0px rgba(51, 75, 78, 0.32);
				backdrop-filter: blur(6px);
				background: #fcc89b;

				svg {
					@include absolute-center;

					path {
						fill: var(--color-primary-500);
					}
				}
			}
		}
	}

	.description {
		margin-top: auto;
		color: var(--color-neutral-white);

		a {
			width: 100%;
		}

		ion-button {
			margin-top: var(--spacing-9);
			font-size: 16px;
			font-weight: 700;
			width: 100%;
		}
	}
}
