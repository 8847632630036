@import 'assets/styles/mixins';
@import 'assets/styles/media';

.wrapper {
	@include map-wrapper();
	@include d-flex(column, flex-start, center);
	position: relative;
	width: 100%;
	aspect-ratio: 684 / 236;
	padding-bottom: var(--spacing-8);
	background-color: var(--apartment-map-bg);

	.content {
		position: relative;
		z-index: 2;
		margin-bottom: 48px;
		text-align: center;
		width: 100%;
		margin: 0 auto;
		margin-top: auto;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		// spacing-6 - margin from edges of wrapper block, 48px - width of action button, spacing-1 - gap between text and action buttons
		max-width: calc(100% - var(--spacing-6) * 2 - 48px * 2 - var(--spacing-1) * 2);
		
		&__id {
			font-size: 20px;
			margin-bottom: var(--spacing-2);
		}
	}

	.marker {
		@include absolute-center;
		opacity: 1;
		transition: opacity 0.3s ease-in-out;
		z-index: 2;
		transform: translate(-50%, -80%);

		&_hidden {
			opacity: 0;
		}
	}

	@include mobile {
		aspect-ratio: 375 / 236;
		max-height: 296px;

		.marker {
			transform: translate(-50%, -88%);
		}
	}
}

.map {
	height: 100%;
}
