@import 'assets/styles/mixins';
@import 'assets/styles/media';

.content {
	@include d-flex(column, center, center, 20px);
	max-width: 327px;
	margin-bottom: 20px;

	&__button {
		position: absolute;
		padding: 4px;
		background: none;
		top: -12px;
		right: -48px;

		&__icon {
			width: 24px;
			height: 24px;
		}
	}

	&__icon {
		svg {
			width: 40px;
			height: 40px;
		}
	}

	&__description {
		@include d-flex(column, center, center, var(--spacing-2));
		text-align: center;
	}
}

@include mobile {
	.content {
		&__button {
			right: -12px;
		}
	}
}
