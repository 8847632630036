@import 'assets/styles/media.scss';
@import 'assets/styles/mixins.scss';

.header {
	@include d-flex(row, flex-start, center, var(--spacing-5));
	width: 100%;
	// auth content gap --spacing-6 + margin-bottom should equals --spacing-9;
	margin-bottom: var(--spacing-4);
	height: 28px;
}
