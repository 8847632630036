@import '~assets/styles/mixins';

.content {
	@include d-flex(column);
	width: 100%;

	ul.list {
		@include d-flex(column);
		gap: var(--spacing-3);
		margin-bottom: var(--spacing-3);
	}
}
