@import 'assets/styles/mixins';
@import 'assets/styles/media';

.description {
	@include d-flex(row, flex-start, flex-start, 4px);

	&__phone {
		color: var(--color-primary-800);
	}

	@include mobile {
		flex-direction: column;
		gap: 0;
	}
}
