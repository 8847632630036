@import 'assets/styles/mixins';
@import 'assets/styles/media.scss';

.wrapper {
	background-color: var(--color-neutral-white);

	.content {
		&_webLogin {
			max-width: none;
		}

		&.content-lg {
			max-width: 684px;
		}
	}

	@include mobile {
		.content {
			position: relative;
			height: 100%;

			&:not(&.content-lg) {
				overflow: hidden;
			}

			& ion-button:last-of-type {
				margin-top: auto;
			}

			&_webLogin {
				overflow-y: auto;
			}
		}
	}
}
