@import 'assets/styles/media.scss';
@import 'assets/styles/mixins';

.content {
	@include d-flex(column);
	max-width: 668px;
	width: 100%;
	margin: 0 auto;

	a {
		text-align: center;
		text-decoration: underline;
	}
}
