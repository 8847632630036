@import 'assets/styles/mixins';

.wrapper {
	@include d-flex(column, center, center);
	width: 60px;
	height: 60px;
	border-radius: 50%;

	&.success {
		background: var(--color-success-50);
	}

	&.failed {
		background: var(--color-error-50);
	}
}
