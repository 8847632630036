.content {
	min-height: 100%;

	// header,
	// &__header {
	// 	flex-direction: column;

	// 	button {
	// 		margin-right: auto;
	// 	}
	// }

	.button {
		&__submit {
			margin-top: var(--spacing-4);
		}
	}
}
