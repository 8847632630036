.item {
	user-select: none;
	padding: 0;
	width: 100%;

	&.disabled {
		opacity: 0.5;
		cursor: default;
	}

	.tooltip {
		width: 100%;
	}

	.fee__wrapper {
		width: 100%;
		flex-direction: row;
	}
}
