@import './media.scss';

@mixin d-flex($direction: row, $justifyContent: null, $alignItems: null, $gap: 0px) {
	display: flex;
	-webkit-display: flex;
	flex-direction: $direction;
	-webkit-flex-direction: $direction;
	justify-content: $justifyContent;
	-webkit-align-items-justify-content: $justifyContent;
	align-items: $alignItems;
	-webkit-align-items-align-items: $alignItems;
	@if ($gap) {
		gap: $gap;
		-webkit-gap: $gap;
	}
}

@mixin typography(
	$color: inherit,
	$fontSize: inherit,
	$fontWeight: inherit,
	$lineHeight: normal,
	$letterSpacing: 0.15px
) {
	color: $color;
	font-size: $fontSize;
	font-weight: $fontWeight;
	line-height: $lineHeight;
	letter-spacing: $letterSpacing;
}

@mixin map-wrapper() {
	position: relative;
	overflow: hidden;
	height: 100%;
}

@mixin slider-pagination($backgroundColor: var(--color-primary-900), $marginTop: auto, $marginBottom: auto) {
	.slider__bullet {
		display: block;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: $backgroundColor;
		opacity: 0.2;
	}

	.slider__bullet_active {
		transform: rotateZ(180deg);
		width: 16px;
		border-radius: 4px;
		background-color: $backgroundColor;
		opacity: 1;
		transition-property: transform width;
		transition-duration: 0.2s;
		transition-timing-function: ease-in;
	}

	.slider__horizontal {
		display: flex;
		gap: 8px;
		justify-content: center;
		margin-bottom: $marginBottom;
		margin-top: $marginTop;

		@include mobile {
			bottom: -32px !important;
		}
	}
}

@mixin scrollbar($width, $trackBackgroundColor, $trackBorderRadius, $thumbBackgroundColor, $thumbBorderRadius) {
	/* width */
	&::-webkit-scrollbar {
		width: $width;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: $trackBackgroundColor;
		border-radius: $trackBorderRadius;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: $thumbBackgroundColor;
		border-radius: $thumbBorderRadius;
	}
}

// TODO: move to animation
@mixin shake {
	@keyframes shake {
		10%,
		90% {
			transform: translate3d(-0.5px, 0, 0);
		}

		20%,
		80% {
			transform: translate3d(1px, 0, 0);
		}

		30%,
		50%,
		70% {
			transform: translate3d(-2px, 0, 0);
		}

		40%,
		60% {
			transform: translate3d(2px, 0, 0);
		}
	}
}

@mixin absolute-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin text-overflow($width: 100%, $whiteSpace: nowrap) {
	white-space: $whiteSpace;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: $width;
}

@mixin full-screen-page {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	height: 100dvh;
	width: 100vw;
	width: 100dvw;
}

@mixin native-password-screen($className) {
	#{$className} {
		@include full-screen-page;
		@include d-flex(column, center, center);

		background-color: var(--color-primary-500);
		padding: 0 var(--spacing-6);

		&__content {
			@include d-flex(column, center, center);
			width: 100%;
			max-width: 327px;
			height: 77vh;
		}

		&_wrong {
			& > div:first-of-type {
				animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
				animation-duration: 0.5s;
			}
		}

		&__description {
			@include d-flex(column, center);
			@include shake;
			gap: var(--spacing-9);
			width: 100%;
			max-width: 455px;
		}

		&__keyboard_wrap {
			@include d-flex(column);
			align-items: center;
			width: 100%;
			margin-top: auto;

			& > ion-button {
				@include typography(var(--color-neutral-white), 16px, 700, 24px);
				outline: none;
				border: none;
				background-color: transparent;
				margin-bottom: var(--spacing-6);
			}
		}
	}
}

@mixin shadow1 {
	box-shadow: 0px 4px 12px 0px rgba(51, 75, 78, 0.08);
}

@mixin shadow2 {
	box-shadow: 0px 8px 16px 0px rgba(51, 75, 78, 0.12);
}

@mixin shadow4 {
	box-shadow: 0px 8px 20px 0px rgba(51, 75, 78, 0.42);
}

@mixin receipt-preview-report {
	@media (max-width: 425px) {
		// @include d-flex(column-reverse, flex-start, flex-start, var(--spacing-3));
		// align-items: flex-start;
		@content;
	}
}

@mixin meters-list-item-separated {
	padding: var(--spacing-6) 0;
	border-bottom: 1px solid var(--color-neutral-50);

	&:first-of-type {
		padding-top: 0;
	}

	&:last-of-type {
		border-bottom: none;
		padding-bottom: 0;
	}
}

@mixin category-icon-filter {
	filter: invert(84%) sepia(65%) saturate(491%) hue-rotate(306deg) brightness(101%) contrast(101%);
}

@mixin host-card-page-color-primary {
	@include d-flex(column);
	background-color: var(--color-primary-500);
	padding: 0 var(--spacing-6);
	padding-bottom: calc(var(--spacing-5) + env(safe-area-inset-bottom));
	padding-top: calc(env(safe-area-inset-top) + var(--spacing-3));
	overflow: hidden;
	height: 100vh;
	height: 100dvh;
	overflow-y: auto;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99;

	& > * {
		// + side paddings
		max-width: calc(448px + (var(--spacing-6) * 2));
		width: 100%;
		margin: 0 auto;
	}

	& > button:first-child {
		max-width: none;
		width: max-content;
		margin: 0;
		margin-bottom: var(--spacing-6);

		svg path {
			stroke: var(--color-neutral-white);
		}
	}

	& > ion-button {
		position: sticky;
		bottom: 0;
		margin-top: auto;
	}
}
