.wrapper {
    & > button {
      position: absolute;
      top: calc(env(safe-area-inset-top) + 10px);
      left: 25px;
      z-index: 1;
  
      & svg path {
        stroke: var(--color-neutral-white);
      }
    }
  
    .content {
      padding-top: 20px;
    }
  }
  