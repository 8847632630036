@import 'assets/styles/mixins.scss';
@import 'assets/styles/media.scss';

div.wrapper {
	max-width: 1326px;
	width: 100%;
	display: grid;
	grid-template-columns: 43.5% 1fr;
	grid-template-rows: 100%;
	gap: var(--spacing-9);
	align-items: flex-start;
	overflow: hidden;
	padding-bottom: var(--spacing-9);

	@include mobile {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr auto;
		gap: var(--spacing-7);

		.banner {
			display: grid;
		}

		@media screen and (max-height: 620px) {
			.banner {
				display: none;
			}
		}
	}
}
