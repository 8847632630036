.wrapper {
    padding-top: var(--spacing-6);
}

.button {
	position: fixed;
	left: 0;
	padding-bottom: var(--spacing-7);
	bottom: 0;
}
