@import 'assets/styles/mixins';

@keyframes splash {
	0% {
		transform: scale(0);
		opacity: 1;
	}
	100% {
		transform: scale(1.5);
		opacity: 0;
	}
}

.wrapper {
	@include d-flex(row, center);
	gap: var(--spacing-3);

	.item {
		position: relative;
		width: 12px;
		height: 12px;
		background-color: transparent;
		border: 1px solid var(--color-neutral-white);
		border-radius: 50%;

		&_active {
			background-color: var(--color-neutral-white);

			&:after {
				content: '';
				display: block;
				top: -5px;
				left: -5px;
				position: absolute;
				border-radius: 50%;
				width: 20px;
				height: 20px;
				background-color: rgba(255, 255, 255, 0.842);
				transform: scale(0);
				animation-name: splash;
				animation-duration: 0.5s;
			}
		}

		&_primary {
			border-color: var(--color-primary-500);

			&.item_active {
				background-color: var(--color-primary-500);

				&::after {
					background-color: var(--color-primary-500);
				}
			}
		}
	}
}
