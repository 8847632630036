@import 'assets/styles/media';

.popover {
	&::part(backdrop) {
		background: none;
	}

	&::part(content) {
		margin-top: 8px;
		width: 328px;
		box-sizing: border-box;
		padding: 0;
		border-radius: 8px;
		background: var(--color-neutral-white);
	}
}

.modal {
	&__header {
		position: relative;
	}

	@include mobile {
		&__header {
			margin-bottom: var(--spacing-4);
		}
	}
}
