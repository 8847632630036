.wrapper {
	&::after {
		content: '';
		top: 0;
		left: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		background: var(--color-primary-500);
		opacity: 0.5;
		z-index: 1;
	}

	.map {
		opacity: 1;
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
		z-index: 0;
		// HBILL-343
		width: 100%;
		height: 100%;

		&_invisible {
			opacity: 0;
		}
	}
}
