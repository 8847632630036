@import 'assets/styles/mixins';

.content {
	@include d-flex(column);
	text-align: right;

	&_title {
		@include text-overflow;
	}
}
