@import 'assets/styles/mixins';
@import 'assets/styles/media';

.wrapper {
	background-color: var(--color-neutral-white);

	.content {
		@include d-flex(column, space-between, stretch, var(--spacing-6));
		max-width: 450px;
		width: 100%;
		margin: 0px auto;

		&.receipt {
			max-width: 684px;
		}
	}

	.button {
		&_back {
			// gap of spacing-6 + margin should be spacing-9
			margin-bottom: var(--spacing-3);
		}

		&_submit {
			margin-top: var(--spacing-5);
		}
	}

	@include mobile {
		.content {
			.button {
				&_back {
					margin-bottom: 0;
				}

				&_submit {
					margin-top: auto;
				}
			}
		}
	}
}
