@import 'assets/styles/mixins';

.wrapper {
	@include d-flex(column, space-between, null, 10px);
	padding-bottom: var(--spacing-3);
	overflow: auto;
	height: 100%;
	padding-bottom: var(--spacing-6);

	.header {
		@include d-flex(row, flex-start, center, var(--spacing-2));
		padding: 14px 24px;
		border-bottom: 1px solid var(--color-neutral-50);

		svg:last-child {
			margin-left: auto;
		}

		span {
			@include typography(var(--color-primary-800), 14px, 700, 21px, null);
		}
	}

	.content {
		@include d-flex(column, center, center);
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: relative;
		z-index: 1;
		background-color: var(--color-neutral-white) !important;

		&__addresses {
			&_empty {
				padding: 0px 16px;
				@include d-flex(column, flex-start, center);

				p {
					@include typography(var(--color-neutral-500), 16px, 400, 24px, null);
					text-align: center;

					&:first-of-type {
						margin-top: 30px;
					}
				}
			}

			&_full {
				@include d-flex(column, flex-start);
				gap: var(--spacing-6);
				height: 100%;
				width: 100%;
				padding: var(--spacing-6) var(--spacing-6) var(--spacing-3);
				overflow-y: auto;
				overflow-x: hidden;
				touch-action: pan-y;
			}

			&_hint {
				width: 100%;
				padding: var(--spacing-6) var(--spacing-6) var(--spacing-3);
			}
		}
	}

	.button {
		width: 100%;
		padding: 0 var(--spacing-6);
		margin-top: auto;
		position: relative;
		z-index: 100;
		background-color: var(--color-neutral-white) !important;
	}

	@include mobile {
		padding-bottom: var(--spacing-4);
	}
}

.loader {
	@include absolute-center;
	width: 56px;
	height: 56px;
}
