@import 'assets/styles/mixins';

.tabs {
	width: 100%;
}

.content {
	margin-top: var(--spacing-4);
	width: 100%;
	height: 100%;
}
