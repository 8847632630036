@import 'assets/styles/mixins';

.content {
	@include d-flex(row, flex-start, center, var(--spacing-4));
	width: 100%;
	padding: var(--spacing-2) var(--spacing-3);

	&__icon {
		width: 24px;
		height: 24px;

		flex-shrink: 0;
	}

	&__description {
		@include d-flex(column);
		overflow: hidden;
		width: 100%;

		& > p {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		& > *:last-child {
			color: var(--color-neutral-500);
		}
	}
}
