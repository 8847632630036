@import 'assets/styles/mixins';
@import 'assets/styles/media';

.content {
	@include d-flex(column, flex-start, center);
	width: 100%;

	&_disabled {
		* {
			filter: grayscale(1);
		}
	}

	&__icon {
		width: 100%;
		max-height: 120px;
		aspect-ratio: 159 / 120;
		object-fit: contain;
	}

	&__title {
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0 var(--spacing-3);
		padding-bottom: var(--spacing-3);
		width: 100%;
	}

	@include mobile {
		&__title {
			font-size: 12px;
			padding: 0 var(--spacing-2);
			padding-bottom: var(--spacing-3);
		}
	}
}
