@import 'assets/styles/mixins';
@import url('https://fonts.googleapis.com/css2?family=Roboto');

.content {
	@include d-flex(column);
	height: 100%;
	width: 100%;

	.list {
		@include d-flex(column);
		gap: var(--spacing-1);
		width: 100%;
		height: 100%;

		&__item {
			@include d-flex(row, space-between, center, var(--spacing-3));
			box-shadow: var(--box-shadow);
			padding: var(--spacing-4);
			border-radius: 10px;
		}

		&__button {
			&_delete {
				svg {
					width: 16px;
					height: 16px;
				}
			}
		}
	}
}
