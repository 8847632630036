@import 'assets/styles/mixins';
@import 'assets/styles/media.scss';

.content {
	@include d-flex(column, flex-start, center, var(--spacing-3));

	&__list {
		@include d-flex(column);
		width: 100%;
	}
}

.card {
	@include d-flex(row, flex-start, center, var(--spacing-3));
	width: 100%;
	overflow: hidden;
	padding: var(--spacing-2) var(--spacing-4);
	background: var(--color-primary-50);
	border-radius: 10px;
	margin: 8px 0;

	&__question {
		flex-shrink: 0;
		cursor: pointer;
	}

	&__info {
		flex-direction: column;
		gap: 4px;

		&__title {
			font-weight: 700;
			font-size: 16px;
			color: var(--color-primary-900);
		}

		&__description {
			font-weight: 400;
			font-size: 14px;
			color: var(--color-neutral-500);
		}
	}
}
