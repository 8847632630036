ion-button {
	--padding-top: var(--spacing-3);
	--padding-bottom: var(--spacing-3);
	--padding-start: var(--spacing-4);
	--padding-end: var(--spacing-4);
	--border-width: 1px;
	--border-radius: var(--spacing-9);
	--box-shadow: none;

	--background-hover-opacity: 0.1;
	--background-activated-opacity: 0.1;

	--color-text: #ffc89a;

	width: 100%;
	margin: 0;
	text-transform: initial;
	min-height: auto;
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0.1px;

	&.button-solid {
		--background: var(--color-primary-500);
		--background-activated: var(--color-neutral-white);
		--background-focused: var(--color-neutral-white);
		--background-hover: var(--color-neutral-white);
		--color: var(--color-text);

		&.button-disabled {
			--background: var(--color-primary-200);
			--color: var(--color-text);
		}

		&.ion-color-primary {
			--ion-color-primary: var(--color-primary-500);
			--ion-color-primary-contrast: var(--color-neutral-white);
			--ion-color-primary-shade: var(--color-neutral-white);
			--ion-color-primary-tint: var(--color-neutral-white);
		}

		&.ion-color-secondary {
			--ion-color-secondary: var(--color-neutral-white);
			--ion-color-secondary-contrast: var(--color-primary-500);
			--ion-color-secondary-shade: var(--color-primary-500);
		}

		.ion-button-content {
			svg path {
				stroke: var(--color-text);
			}
		}
	}

	&.button-clear {
		opacity: 1;

		&.ion-color-primary {
			--ion-color-primary: var(--color-primary-500);
			--ion-color-primary-contrast: var(--color-primary-500);
			--ion-color-primary-shade: var(--color-primary-500);
		}

		&.ion-color-secondary {
			--ion-color-secondary: var(--color-neutral-white);
			--background: transparent;
			--background-activated: var(--color-neutral-white);
			--background-hover: var(--color-neutral-white);
		}

		&.ion-color-danger {
			.ion-button-content {
				svg path {
					stroke: var(--color-error-500);
				}
			}
		}

		.ion-button-content {
			svg path {
				stroke: var(--color-primary-500);
			}
		}

		&.button-disabled {
			--color: var(--color-primary-200);

			.ion-button-content {
				svg path {
					stroke: var(--color-primary-200);
				}
			}
		}
	}

	&.button-outline {
		&.button-disabled {
			--border-color: var(--color-primary-200);
		}

		&.ion-color-primary {
			--ion-color-primary: var(--color-primary-500);
			--ion-color-primary-contrast: var(--color-primary-500);
			--ion-color-primary-shade: var(--color-primary-500);
			--ion-color-primary-tint: var(--color-primary-500);
		}

		&.ion-color-secondary {
			--ion-color-secondary: var(--color-neutral-white);
			--ion-color-secondary-shade: var(--color-neutral-white);
			--ion-color-secondary-contrast: var(--color-neutral-white);
		}
	}

	&.button-disabled {
		opacity: 1;
		--color: var(--color-primary-200);

		&.ion-color-danger {
			.ion-button-content {
				svg path {
					stroke: var(--color-error-200);
				}
			}
			--ion-color-danger: var(--color-error-200);
		}

		&.ion-color-secondary {
			--ion-color-secondary-contrast: var(--color-primary-200);
			--ion-color-secondary: var(--color-primary-200);

			&.button-solid {
				--ion-color-secondary: var(--color-neutral-white);
			}
		}

		&.ion-color-primary {
			--ion-color-primary: var(--color-primary-200);
		}
	}

	.ion-button-content {
		@include d-flex(row, center, center, var(--spacing-2));
		line-height: 150%;

		& ion-spinner {
			width: 20px;
			height: 20px;
		}
	}

	&.no-ripple-effect {
		--ripple-color: transparent;
		--background-hover: none;
	}

	&.button-large {
		--padding-top: 14px;
		--padding-bottom: 14px;
		--padding-start: var(--spacing-6);
		--padding-end: var(--spacing-6);
		font-size: 16px;
	}
}
