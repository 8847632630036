@import 'assets/styles/mixins';

.content {
	@include d-flex(column, flex-start, center);

	&__logos {
		@include d-flex(row, center, center, var(--spacing-5));
		margin: 0 auto;
		margin-bottom: var(--spacing-9);
	}
}

#button_submit {
	--background: var(--color-neural-white) !important;
	--color: var(--color-primary-500) !important;
}
