@import 'assets/styles/mixins';

.content {
	@include d-flex(row, space-between, center, var(--spacing-3));
	@include shadow1;
	padding: var(--spacing-4);
	min-height: auto;
	border-radius: var(--spacing-2);

	& > svg {
		transform: rotateY(180deg);
		height: 16px;
		width: 16px;
	}
}
