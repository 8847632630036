@import 'assets/styles/media.scss';
@import 'assets/styles/mixins.scss';

.header {
	@include d-flex(row, space-between, center, 20px);
	min-height: 100px;
	max-height: 100px;
	padding: var(--spacing-6) var(--spacing-7);
	background-color: var(--color-neutral-white);
	width: 100%;
	position: sticky;
	top: 0;
	z-index: 100;
	overflow: hidden;

	a {
		height: unset;
		line-height: normal;
		font-size: 0;
		user-select: none;
	}

	&__logo {
		max-width: 122px;
	}

	&__navbar {
		display: flex;
		position: static;
		width: max-content;
	}

	&__link {
		&_disabled {
			pointer-events: none;
		}
	}

	@include mobile {
		padding: 0 24px;
		display: none;
		background: none;
		min-height: auto;
		max-height: max-content;
		position: relative;
		background-color: var(--color-neutral-layout-bg);

		&__logo {
			width: 100px;
		}

		&__navbar {
			display: none !important;
		}
	}
}
