@import 'assets/styles/mixins';

.content {
	@include d-flex(row, space-between, center, var(--spacing-5));
	width: 100%;
	overflow: hidden;
	margin: 0;
	height: 64px;
	padding: 0;

	&:last-of-type {
		margin-bottom: 0;
	}

	&__icon {
		height: 24px;
		object-fit: contain;
		flex-shrink: 0;
		filter: var(--filter-color-primary-500);
	}

	&__info {
		@include d-flex(column);
		width: 100%;
		overflow: hidden;

		& > * {
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.amounts {
		@include d-flex(column, center, flex-end);
	}
}
