@import 'assets/styles/mixins';
@import 'assets/styles/media.scss';

.payment {
	@include d-flex(column, flex-start, center);
	position: relative;
	height: 100%;
	width: 100%;

	&__content {
		@include d-flex(column, flex-start, center);
		margin: 140px auto;
		max-width: 687px;
		width: 100%;
	}

	&__logo {
		display: none;
		align-self: flex-start;
		margin-right: auto;
	}

	&__actions {
		@include d-flex(column);
		gap: var(--spacing-4);
		margin-top: 140px;
		width: 100%;
		max-width: 320px;
	}
}

.loader {
	margin-top: var(--spacing-4);
	width: 56px;
	height: 56px;
}

.title_payed {
	@include d-flex(row, center, center, 4px);
	flex-wrap: wrap;
}

@include mobile {
	.payment {
		margin: 24px;

		&__logo {
			display: inline-block;
		}

		&__content {
			height: 100%;
			margin: 0;
			margin-top: 80px;
		}

		&__actions {
			margin-top: auto;
		}
	}
}
