@import '~assets/styles/mixins';

.wrapper {
	background-color: var(--color-neutral-layout-bg);

	.note {
		padding: 0;
		margin-bottom: var(--spacing-5);
	}

	.content {
		@include d-flex(column);
		gap: var(--spacing-3);
		width: 100%;
	}
}
