@import 'assets/styles/mixins';

.wrapper {
	@include d-flex(column);
	width: 100%;

	.list {
		&__item {
			.content {
				@include d-flex(row, space-between, center, var(--spacing-3));
			}

			.note {
				padding: 0 var(--spacing-3);
			}

			&.disabled {
				opacity: 0.5;
				pointer-events: none;
			}
		}
	}

	ion-button {
		width: max-content;
	}
}

@include mobile {
	.note {
		text-align: center;
	}
}