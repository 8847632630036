@import 'assets/styles/mixins';

.content {
    @include d-flex(column, center, center, var(--spacing-5));

    .icon {
        width: 32px;
        height: 32px;
    }

    .button {
        display: block;
        width: 100%;
        border-top: 1px solid var(--color-neutral-300);
        text-align: center;
        padding-top: var(--spacing-2);
    }
}
