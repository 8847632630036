@import 'assets/styles/mixins';

.card {
	width: 100%;
	height: max-content;
	background-color: transparent;
	perspective: 1000px;
	cursor: pointer;

	&__inner {
		width: 100%;
		position: relative;
		transition: transform 0.6s;
		transform-style: preserve-3d;
		
		&_front,
		&_back {
			@include d-flex(row, center, center);
			width: 100%;
			height: 100%;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			top: 0;
			left: 0;
			z-index: 1;


			& > div.card__preview {
				opacity: 1;
			}
		}

		&_back {
			transform: rotateY(180deg);
			position: absolute;
		}

		.settings {
			position: absolute;
			right: var(--spacing-3);
			top: var(--spacing-3);

			svg path {
				fill: var(--color-neutral-white);
			}
		}
	}

	&_flipped {
		.card__inner {
			transform: rotateY(-180deg);
		}
	}
}
