@import 'assets/styles/mixins';
@import 'assets/styles/media.scss';

.wrapper {
	height: 100%;

	ion-button {
		margin-top: var(--spacing-4);
		position: sticky;
		bottom: 0;
		z-index: 10;
	}

	// @include mobile {
	// 	&_withKeyboard {
	// 		ion-button {
	// 			position: sticky;
	// 			bottom: 0;
	// 			z-index: 10;
	// 		}
	// 	}
	// }
}
