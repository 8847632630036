@import '~assets/styles/mixins';

.content {
	@include d-flex(row, space-between, center, var(--spacing-5));
	width: 100%;
}

.loader {
	width: 26px;
	height: 18px;
}
