@import 'assets/styles/mixins';

.swiper {
	width: 100%;
}

.link_restore {
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	letter-spacing: 0.15px;
	margin-top: 16px;
	color: inherit;
	display: block;
}

.verifyWeb {
	@include d-flex(column, flex-start, flex-start, var(--spacing-6));
	max-width: calc(448px + (var(--spacing-6) * 2));
	width: 100%;
	margin: 0 auto;
}
