@import 'assets/styles/mixins';
@import 'assets/styles/media';

.layout {
	background-color: var(--color-primary-layout-bg);
	overflow-x: clip;

	@include mobile {
		background-color: var(--color-neutral-layout-bg);

		header.header {
			display: none;
		}
	}
}
