@import 'assets/styles/mixins';

.wrapper {
	height: 100%;
	margin: 0 auto;

	.content {
		@include d-flex(column);
		gap: var(--spacing-3);
		height: 100%;
		width: 100%;
		max-width: 400px;
		padding: var(--spacing-6);
		margin: 0 auto;
		overflow-y: auto;

		@include mobile {
			padding: var(--spacing-3);
		}

		.advantages {
			&__list {
				@include d-flex(column);
				gap: var(--spacing-4);
				margin-bottom: var(--spacing-5);

				& > li {
					@include d-flex(row, flex-start, center, var(--spacing-2));

					svg path {
						stroke: var(--color-primary-500);
					}
				}

				@include mobile-small-height {
					margin-bottom: var(--spacing-3);
				}
			}
		}

		.card {
			margin: 0 auto;
			flex-shrink: 0;
			padding-top: 0;
		}

		.qr {
			max-width: 124px;
			width: 100%;
			margin: 0 auto;
			aspect-ratio: 1 / 1;

			svg {
				object-fit: contain;
				width: 100%;
				height: 100%;
			}
		}

		ion-button {
			margin-top: auto;
		}

		@include mobile-small-height {
			.title {
				margin-bottom: 0;
			}
		}
	}

	&--web-ios {
		overflow: auto;
		touch-action: pan-y;

		.content {
			overflow: unset
		}
	}
}
