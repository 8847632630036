@import 'assets/styles/mixins';
@import 'assets/styles/media';

.wrapper {
	@include map-wrapper();
	min-height: 120px;

	.marker {
		position: absolute;
		transform: translate(20%, -50%);
		top: 50%;
		right: 0;
	}
}
