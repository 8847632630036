@import 'assets/styles/media.scss';
@import 'assets/styles/mixins';

div.wrapper {
	@include shadow2;
	@include d-flex(column, flex-start, center);
	overflow: hidden;
	border-radius: var(--spacing-4);
	padding: var(--spacing-9);

	.icon {
		@include d-flex(row, center, center);
		background: var(--color-success-50);
		border-radius: 50%;
		margin-bottom: var(--spacing-7);
	}

	.actions {
		max-width: 320px;
		margin-top: 60px;

		ion-button {
			margin-top: var(--spacing-6);
		}

		.button__auth {
			display: none;
		}
	}

	@include mobile {
		box-shadow: none;
		padding: 0;

		.actions {
			margin-top: 40px;

			.button__auth {
				display: flex;
			}
		}
	}
}
