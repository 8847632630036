@import 'assets/styles/mixins';
@import 'assets/styles/media';

.header {
	@include d-flex(row, space-between, center, var(--spacing-3));
	width: 100%;

	&__actions {
		@include d-flex(row, flex-start, center, var(--spacing-3));
		align-self: flex-start;

		.icon {
			&__delete,
			&__edit {
				width: 24px;
				aspect-ratio: 1/1;
			}

			&__edit path {
				fill: var(--color-primary-500);
			}
		}
	}

	@include receipt-preview-report {
		&__actions {
			margin-left: auto;
		}
	}
}

.tariff__list {
	@include d-flex(column);
	width: 100%;
	gap: var(--spacing-2);
}
