@import 'assets/styles/mixins';
@import url('https://fonts.googleapis.com/css2?family=Roboto');

.content {
	@include d-flex(column);
	gap: var(--spacing-3);
	width: 100%;
	height: 100%;
	overflow: hidden;

	.list {
		@include d-flex(column);
		gap: var(--spacing-4);
		width: 100%;
		overflow-y: auto;

		.contacts {
			@include d-flex(column);
			gap: var(--spacing-2);
			width: 100%;
		}
	}
}
