@import 'assets/styles/mixins';
@import 'assets/styles/media';

.wrapper {
	background-color: var(--color-neutral-white);
}

.list {
	width: 100%;
}
