@import 'assets/styles/mixins';
@import 'assets/styles/media';


.wrapper {
	@include d-flex(row);
	gap: var(--spacing-5);
	width: 100%;
	background-color: var(--color-primary-500);
	border-radius: var(--spacing-2);
	overflow: hidden;
	padding-right: var(--spacing-3);
	cursor: pointer;

	.logo {
		flex-basis: 122px;
		height: 100%;
		position: relative;

		svg {
			position: absolute;
			height: 100%;
		}
	}

	.content {
		@include d-flex(column, center, flex-start, var(--spacing-2));
		width: fit-content;
		padding: var(--spacing-4) 0;
		z-index: 2;
		white-space: nowrap;

		@include mobile-md {
			.title {
				font-size: 20px;
			}	

			.subtitle {
				font-size: 14px;
			}
		}
	}

	.modal {
		--max-height: 90vh;
		max-height: auto;
	}
}
