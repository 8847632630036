@import 'assets/styles/mixins';
@import 'assets/styles/media';

.wrapper {
	background-color: var(--color-neutral-white);

	.header {
		flex-direction: row;
		gap: var(--spacing-4);

		&__button {
			svg path {
				fill: var(--color-primary-500);
			}
		}
	}
}

.list {
	width: 100%;
}
