@import 'assets/styles/media.scss';
@import 'assets/styles/mixins';

.content {
	box-shadow: 0px 8px 16px 0px rgba(51, 75, 78, 0.12);
	max-width: 684px;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: var(--spacing-4);
	display: grid;
	grid-template-rows: 232px 1fr;
	padding-top: env(safe-area-inset-top);
	transition: all 0.4s ease-in-out;

	&_collapsed {
		grid-template-rows: 128px 1fr !important;
	}

	&_mapDisabled {
		display: block;
	}

	.receipt {
		@include d-flex(column);
		width: 100%;
		overflow: hidden;
		border-radius: var(--spacing-5) var(--spacing-5) 0px 0px;
		margin-top: -20px;
		z-index: 2;
		background-color: var(--color-neutral-white);
		height: calc(100% + 20px);

		&__header {
			@include d-flex(row, space-between, center, var(--spacing-3));
			margin-bottom: var(--spacing-3);
		}

		&__list {
			@include d-flex(column, flex-start, center, 24px);
			width: 100%;
			height: 100%;
			overflow: auto;
			padding: 24px 24px 20px 24px;
			flex-grow: 1;

			&_text {
				display: flex;
				color: var(--color-primary-500);
				margin: 0 auto;

				&:hover {
					background-color: transparent;
				}
			}
		}

		&__item {
			width: 100%;

			ion-button {
				display: block;
				margin: 0 auto;
			}
		}

		&__header {
			@include d-flex(row, space-between, center, 24px);
			width: 100%;
		}
	}

	.button {
		width: 100%;
		box-shadow: 0px 8px 20px 0px rgba(51, 75, 78, 0.42);
		border-radius: 20px 20px 0px 0px;
		padding: 24px;
		background-color: var(--color-neutral-white);
		position: sticky;
		bottom: 0;
		flex-grow: 0;
	}

	@include mobile {
		border-radius: 0;
		height: 100vh;
		height: 100dvh;
		position: fixed;
		max-width: none;
		padding-bottom: env(safe-area-inset-bottom);
		top: 0;
		left: 0;
		z-index: 1;
		grid-template-rows: 212px 1fr;

		&_mapDisabled {
			.receipt {
				margin-top: env(safe-area-inset-top);
			}
		}
	}
}
