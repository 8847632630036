@import 'assets/styles/mixins';

.list {
	@include d-flex(column);
	gap: var(--spacing-3);
	width: 100%;
	align-self: center;
}

.info {
	@include d-flex(row, flex-start, center, var(--spacing-3));
	margin-top: auto !important;

	img {
		width: 24px;
		object-fit: contain;
	}
}

ion-button.button {
	margin-top: var(--spacing-6) !important;
}
