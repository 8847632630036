@import 'assets/styles/mixins';

.wrapper {
	@include d-flex(column);
	gap: var(--spacing-4);
	width: 100%;
	padding: 0 var(--spacing-4);

	.detail {
		@include d-flex(row, space-between, center, var(--spacing-3));
		width: 100%;
		flex-wrap: nowrap;
	}
}
