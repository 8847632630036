@import 'assets/styles/mixins';

.wrapper {
	width: 100%;

	.header {
		@include d-flex(row, space-between, center, var(--spacing-3));
		margin-bottom: var(--spacing-3);
	}
}
