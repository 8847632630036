@import 'assets/styles/mixins';

.content {
	@include d-flex(column, flex-start, center, var(--spacing-3));

	&__list {
		@include d-flex(column);
		width: 100%;
	}
}
