@import '~assets/styles/mixins';

.content {
	@include d-flex(row);
	gap: var(--spacing-2);
	padding: 0;
	width: 100%;

	.icon {
		width: 24px;
		flex-shrink: 0;
	}

    .item {
        padding: 0;
        min-height: unset;
    }
}
