.wrapper {
	overflow: hidden;

	.content {
		// height: 100%;
		padding: 0 var(--spacing-2) var(--spacing-3);

		&__header {
			width: 100%;
			align-items: flex-start;

			button {
				margin-bottom: var(--spacing-3);
			}
		}
	}
}
