@import 'assets/styles/media.scss';
@import 'assets/styles/mixins.scss';

.navbar {
	padding-bottom: env(safe-area-inset-bottom);
	font-weight: bold;
	position: sticky !important;
	bottom: 0;
	display: none;
	gap: var(--spacing-9);
	height: max-content;
	background-color: transparent;

	&__action {
		@include d-flex(row, space-between);
		gap: var(--spacing-2);

		&.selected {
			svg {
				filter: brightness(0%);
			}
		}
	}

	&_hidden {
		display: none !important;
	}

	@include mobile {
		gap: 0;
		height: auto !important;
		padding: var(--spacing-2) 0;
		padding-bottom: calc(var(--spacing-2) + env(safe-area-inset-bottom));
		margin-top: auto;
		display: flex;
		z-index: 10;
		box-shadow: 0px -12px 12px -10px rgba(51, 75, 78, 0.12);
		background-color: var(--color-neutral-white);

		&__action {
			flex-direction: column;
			gap: 0;
		}

		&__label {
			font-size: 12px;

			&.selected {
				font-size: 12px;
			}
		}
	}
}
