@import 'assets/styles/mixins';

.wrapper {
	@include d-flex(row, space-between, center, var(--spacing-2));
	width: 100%;
}

.switch {
	margin-left: 0;
}
