@import 'assets/styles/mixins';
@import 'assets/styles/media.scss';

.card {
	@include d-flex(column, null, null, 4px);
	margin-top: 12px;
	border-radius: 8px;
	background: var(--color-neutral-white);
	box-shadow: 0 8px 16px 0 rgba(51, 75, 78, 0.12);
	padding: 12px;
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);

	&:hover {
		transition-property: transform, box-shadow;
		transition-duration: 0.3s;
		transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
		transform: scale(1.03);
		box-shadow: 0 8px 16px 0 rgba(51, 75, 78, 0.288);
		cursor: pointer;
	}

	&__description {
		@include text-overflow;
	}

	@include mobile {
		transition-property: none;

		&:hover {
			transition-property: none;
			box-shadow: 0 8px 16px 0 rgba(51, 75, 78, 0.12);
		}
	}
}
