@font-face {
  font-family: 'NunitoSans';
  src: url('./NunitoSans/NunitoSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('./NunitoSans/NunitoSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('./NunitoSans/NunitoSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('./NunitoSans/NunitoSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
}