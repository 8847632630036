@import 'assets/styles/mixins';

.avatar {
	&__modal {
		@include d-flex(column, flex-start, center);
		padding-top: var(--spacing-8);

		label {
			width: 100%;
		}
	}

	&__delete {
		margin-top: var(--spacing-3);
	}

	&__input {
		display: none;
	}

	&__close {
		margin-top: var(--spacing-9);
	}
}
