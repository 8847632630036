#apple-pay-button {
	--apple-pay-button-height: 40px;
	width: 100%;
	display: inline-block;
	-webkit-appearance: -apple-pay-button;
	-apple-pay-button-type: plain;
	-apple-pay-button-style: black;
	border-radius: var(--spacing-9);
	overflow: hidden;
}
