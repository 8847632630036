@import 'assets/styles/mixins';

.button {
	@include d-flex(row, null, center, 16px);
	height: 60px;
	width: 100%;
	background: none;
	padding: 0 12px;

	&__description {
		display: flex;
		justify-content: flex-start;
	}
}
