@import '~assets/styles/mixins';

.header {
	@include d-flex(row, space-between, center, var(--spacing-2));
	margin-bottom: var(--spacing-5);
	width: 100%;

	&__text {
		@include d-flex(row);
		gap: var(--spacing-2);
	}
}
